// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import EsrDashboard from './pages/EsrDashboard';
import Settings from './pages/Settings';

const App = () => {
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ flex: 1, padding: '0px', marginLeft: '55px' }}>
          <Routes>
            <Route path="/esr-Dashboard" element={<EsrDashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/esr-Dashboard" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;

// const TestComponent = () => (
//   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
//     <div className="bg-red-500 p-5">Card 1</div>
//     <div className="bg-blue-500 p-5">Card 2</div>
//     <div className="bg-green-500 p-5">Card 3</div>
//   </div>
// );

// export default TestComponent;


