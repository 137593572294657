import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraphForOverview = ({ graphData, graphLabels, lineColor, graphBackground }) => {

  // console.log('graghdata---',graphData, graphLabels)
  // Prepare chart data using the graphData and graphLabels passed
  const chartData = {
    labels: graphLabels, // X-axis labels (e.g., months)
    datasets: [
      {
        data: graphData, // Y-axis data (metrics values)
        backgroundColor: graphBackground,
        borderColor: lineColor,
        borderWidth: 2,
        fill: false,
        tension: 0.4, // Makes the line smooth
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to resize with the container
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Light grid color
        },
        ticks: {
          stepSize: 50, // Controls the interval between ticks
          maxTicksLimit: 5, // Limits the number of ticks displayed
          callback: function(value) {
            // Adjust Y-axis labels to display shorter values
            if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + 'M'; // Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'K'; // Thousands
            } else {
              return value; // Return regular value for small numbers
            }
          },
        },
        suggestedMin: 0,  // Minimum value for the Y-axis
        suggestedMax: 300, // Maximum value for the Y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label || 'Value'}: ${tooltipItem.raw}`, // Customize tooltip
        },
      },
    },
  };
  

  return (
    <div className="relative h-full w-full">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineGraphForOverview;
