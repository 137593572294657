// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LineChart = ({ data, options, lineColor }) => {

  const updatedOptions = {
    ...options,
    elements: {
      line: {
        borderColor: lineColor
      }
    }
  };

  return <Line data={data} options={updatedOptions} />;
};

export default LineChart;
