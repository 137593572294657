import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../apiService'; 
import axios from 'axios';
import energyConsumptionData from '../utils/sampleData/energyConsumptionData';
import carbonFootprint from '../utils/sampleData/carbonFootprintData';
import waterUsage from '../utils/sampleData/waterUsage';
import wasteManagement from '../utils/sampleData/wasteManagement';
import airQuality from '../utils/sampleData/airQuality';
import greenSpaces from '../utils/sampleData/greenSpaces';
import transportation from '../utils/sampleData/transportation';
import building from '../utils/sampleData/building';
import compliance from '../utils/sampleData/compliance';

// Initial state with dummy data
const initialState = {
  data: {
    energyConsumption: energyConsumptionData,
    carbonFootprint: carbonFootprint,
    waterUsage: waterUsage,
    wasteManagement : wasteManagement,
    airQuality: airQuality,
    greenSpaces: greenSpaces,
    transportation: transportation,
    building: building,
    compliance:compliance
  },

  
  status: 'idle',
  error: null,
};

// Async thunk to fetch KPI data from multiple APIs
export const fetchKpiDataThunk = createAsyncThunk('esr/fetchKpiData', async () => {
  const kpis = [
    'energyConsumption',
    'carbonFootprint',
    'waterUsage',
    'wasteManagement',
    'airQuality',
    'greenSpaces',
    'transportation',
    'building',
    'compliance'
    // Add other KPIs here
  ];

  const fetchDataForKpi = async (kpi) => {
    try {
      const response = await axios.get(apiEndpoints.esr[kpi]);
      return { [kpi]: response.data }; // Return in a format that maps the KPI to its data
    } catch (error) {
      throw new Error(`Failed to fetch data for ${kpi}: ${error.message}`);
    }
  };

  // Fetch all KPI data concurrently
  const results = await Promise.all(kpis?.map(kpi => fetchDataForKpi(kpi)));

  // Merge all results into a single object
  const kpiData = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  return kpiData;
});

// Create slice
const esrSlice = createSlice({
  name: 'esr',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKpiDataThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKpiDataThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Merge fetched data into the state
        state.data = {
          ...state.data,
          ...action.payload, // Updates each KPI's data with fetched data
        };
      })
      .addCase(fetchKpiDataThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default esrSlice.reducer;