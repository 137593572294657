const energyConsumptionData = {
  2024: {
    Jan: {
      1: {
        totalEnergyUsage: { value: 120000, unit: "kWh" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Energy Savings':{value:2000, unit:'kWh'},
        'renewable Energy Usage': { value: 80, unit: "%" },
        'Non-Renewable Energy':{value:20, unit:'%'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 0.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      31: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 85, unit: "%" },
        'energy Efficiency Ratio': { value: 0.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:15, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      }
    },
    feb: {
      1: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 180000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      20: {
        totalEnergyUsage: { value: 140000, unit: "kWh" },
        'renewable Energy Usage': { value: 30, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:70, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Mar: {
      1: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 50, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:50, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Apr: {
      1: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 50, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:50, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 110000, unit: "kWh" },
        'renewable Energy Usage': { value: 35, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:65, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 30, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:70, unit:'%'},
        'Energy Savings':{value:1400, unit:'kWh'},
      },
    },
    May: {
      1: {
        totalEnergyUsage: { value: 210000, unit: "kWh" },
        'renewable Energy Usage': { value: 40, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:60, unit:'%'},
        'Energy Savings':{value:2500, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 150000, unit: "kWh" },
        'renewable Energy Usage': { value: 90, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:10, unit:'%'},
        'Energy Savings':{value:2600, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
    },
    Jun: {
      1: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2800, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 130000, unit: "kWh" },
        'renewable Energy Usage': { value: 55, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:45, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 68, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:32, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    },
    Jul: {
      1: {
        totalEnergyUsage: { value: 210000, unit: "kWh" },
        'renewable Energy Usage': { value: 500, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:4000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 110000, unit: "kWh" },
        'renewable Energy Usage': { value: 63, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:37, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 79, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:21, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Aug: {
      1: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 120000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2600, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 63, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:37, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    },
    Sep: {
      1: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 58, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:42, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:4000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:3500, unit:'kWh'},
      },
    },
    Oct: {
      1: {
        totalEnergyUsage: { value: 190000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},

      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 64, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:36, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},
      },
    },
    Nov: {
      1: {
        totalEnergyUsage: { value: 180000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 90, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:10, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:6000, unit:'kWh'},
      },
    },
    Dec: {
      1: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 32, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:68, unit:'%'},
        'Energy Savings':{value:3400, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 150000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:1200, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 22, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:78, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    }
  },
  2023: {
    Jan: {
      1: {
        totalEnergyUsage: { value: 120000, unit: "kWh" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Energy Savings':{value:2000, unit:'kWh'},
        'renewable Energy Usage': { value: 80, unit: "%" },
        'Non-Renewable Energy':{value:20, unit:'%'},
      },
      15: {
        totalEnergyUsage: { value: 150000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 0.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      31: {
        totalEnergyUsage: { value: 130000, unit: "kWh" },
        'renewable Energy Usage': { value: 85, unit: "%" },
        'energy Efficiency Ratio': { value: 0.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:15, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      }
    },
    feb: {
      1: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 190000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      20: {
        totalEnergyUsage: { value: 120000, unit: "kWh" },
        'renewable Energy Usage': { value: 30, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:70, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Mar: {
      1: {
        totalEnergyUsage: { value: 240000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 180000, unit: "kWh" },
        'renewable Energy Usage': { value: 50, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:50, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Apr: {
      1: {
        totalEnergyUsage: { value: 230000, unit: "kWh" },
        'renewable Energy Usage': { value: 50, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:50, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 114000, unit: "kWh" },
        'renewable Energy Usage': { value: 35, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:65, unit:'%'},
        'Energy Savings':{value:1500, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 204000, unit: "kWh" },
        'renewable Energy Usage': { value: 30, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:70, unit:'%'},
        'Energy Savings':{value:1400, unit:'kWh'},
      },
    },
    May: {
      1: {
        totalEnergyUsage: { value: 256000, unit: "kWh" },
        'renewable Energy Usage': { value: 40, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:60, unit:'%'},
        'Energy Savings':{value:2500, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 176000, unit: "kWh" },
        'renewable Energy Usage': { value: 90, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:10, unit:'%'},
        'Energy Savings':{value:2600, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 226000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
    },
    Jun: {
      1: {
        totalEnergyUsage: { value: 258000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2800, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 183000, unit: "kWh" },
        'renewable Energy Usage': { value: 55, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:45, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 204000, unit: "kWh" },
        'renewable Energy Usage': { value: 68, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:32, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    },
    Jul: {
      1: {
        totalEnergyUsage: { value: 270000, unit: "kWh" },
        'renewable Energy Usage': { value: 500, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:4000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 140000, unit: "kWh" },
        'renewable Energy Usage': { value: 63, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:37, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 79, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:21, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
    },
    Aug: {
      1: {
        totalEnergyUsage: { value: 260000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:1800, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 120000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:2600, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 270000, unit: "kWh" },
        'renewable Energy Usage': { value: 63, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:37, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    },
    Sep: {
      1: {
        totalEnergyUsage: { value: 258000, unit: "kWh" },
        'renewable Energy Usage': { value: 58, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:42, unit:'%'},
        'Energy Savings':{value:2000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 65, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:35, unit:'%'},
        'Energy Savings':{value:4000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 204800, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:3500, unit:'kWh'},
      },
    },
    Oct: {
      1: {
        totalEnergyUsage: { value: 94000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},

      },
      15: {
        totalEnergyUsage: { value: 38000, unit: "kWh" },
        'renewable Energy Usage': { value: 70, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:30, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 29000, unit: "kWh" },
        'renewable Energy Usage': { value: 64, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:36, unit:'%'},
        'Energy Savings':{value:5000, unit:'kWh'},
      },
    },
    Nov: {
      1: {
        totalEnergyUsage: { value: 189000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:1000, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 100000, unit: "kWh" },
        'renewable Energy Usage': { value: 90, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:10, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 200000, unit: "kWh" },
        'renewable Energy Usage': { value: 80, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:20, unit:'%'},
        'Energy Savings':{value:6000, unit:'kWh'},
      },
    },
    Dec: {
      1: {
        totalEnergyUsage: { value: 130000, unit: "kWh" },
        'renewable Energy Usage': { value: 32, unit: "%" },
        'energy Efficiency Ratio': { value: 0.8, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:68, unit:'%'},
        'Energy Savings':{value:3400, unit:'kWh'},
      },
      15: {
        totalEnergyUsage: { value: 190000, unit: "kWh" },
        'renewable Energy Usage': { value: 60, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:40, unit:'%'},
        'Energy Savings':{value:1200, unit:'kWh'},
      },
      25: {
        totalEnergyUsage: { value: 220000, unit: "kWh" },
        'renewable Energy Usage': { value: 22, unit: "%" },
        'energy Efficiency Ratio': { value: 1.81, unit: "kWh/m²" },
        'Non-Renewable Energy':{value:78, unit:'%'},
        'Energy Savings':{value:3000, unit:'kWh'},
      },
    }
  },
}


export default energyConsumptionData;
