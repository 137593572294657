// EsrDashboard.js
import React, { useState, useEffect } from 'react';
import { List, ListItem, Button } from '@mui/material';
import EsrOverview from '../components/EsrDashboard/EsrOverview';
import Header from '../components/header';
import { useDateFilter } from '../context/DateFilterContext';

const EsrDashboard = () => {
  const { year, monthRange } = useDateFilter();
  const [activeComponent, setActiveComponent] = useState('Overview');
  const [highlightPosition, setHighlightPosition] = useState(0);

  const handleRefresh = () => {
    // console.log('Refreshed data');
  };

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const startMonthName = monthNames[monthRange.start - 1];
  const endMonthName = monthNames[monthRange.end - 1];

  const components = {
    Overview: <div><EsrOverview year={year} start={startMonthName} end={endMonthName} /></div>,
    Energy: <div className='ml-10'>Content for Energy Under dev</div>,
    'Carbon Footprint': <div className='ml-10'>Content for footprint Under dev</div>,
    Water: <div className='ml-10'>Content for water Under dev</div>,
    Waste: <div className='ml-10'>Content for waste Under dev</div>,
    'Air Quality': <div className='ml-10'>Content for air quality Under dev</div>,
    'Green Spaces': <div className='ml-10'>Content for green spaces Under dev</div>,
    Transportation: <div className='ml-10'>Content for transportation Under dev</div>,
    Building: <div className='ml-10'>Content for building Under dev</div>,
    Compliance: <div className='ml-10'>Content for compliance Under dev</div>,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const menuItems = [
    { name: 'Overview', icon: './overview.svg', icon1: './overview1.svg' },
    { name: 'Energy', icon: './energy.svg', icon1: './energy1.svg' },
    { name: 'Carbon Footprint', icon: './carbon.svg', icon1: './carbon1.svg' },
    { name: 'Water', icon: 'water.svg', icon1: 'water1.svg' },
    { name: 'Waste', icon: 'waste.svg', icon1: 'waste1.svg' },
    { name: 'Air Quality', icon: 'air.svg',icon1: 'air1.svg',  },
    { name: 'Green Spaces', icon: 'green.svg',  icon1: 'green1.svg' },
    { name: 'Transportation', icon: 'transport.svg', icon1: 'transport1.svg' },
    { name: 'Building', icon: 'building.svg', icon1: 'building1.svg' },
    { name: 'Compliance', icon: 'compliance.svg',  icon1: 'compliance1.svg' },
  ];

  const handleMenuClick = (component, index) => {
    setActiveComponent(component);
    setHighlightPosition(index * 66); 
  };

  useEffect(() => {
    setHighlightPosition(menuItems.findIndex(item => item.name === activeComponent) * 66);
  }, [activeComponent, menuItems]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Content Area - Left (80%) */}
      <div className="flex-1 w-full lg:w-[82%] h-screen bg-[#E9EEFF] overflow-hidden p-2 mb-1 ">
        <Header title={activeComponent} onRefresh={handleRefresh} />
        {components[activeComponent]}
      </div>

      {/* Right Menu  */}
      <div className="w-full md:w-[18%] h-screen flex flex-col items-start bg-[#217efd] fixed lg:relative right-0 top-0">
        <List className="relative w-full p-0">
          {menuItems.map((item, index) => (
            <ListItem key={index} className="p-0 relative">
              <Button
                onClick={() => handleMenuClick(item.name, index)}
                className={`relative z-10 w-full lg:w-[220px] h-[50px] 2xl:w-[400px] flex items-center justify-start hover:bg-transparent`}
              >
                {activeComponent === item.name ? 
                <img
                  src={item.icon1}
                  alt={`${item.name} logo`}
                  className="w-6 h-6 mr-2"
                  // style={{ backgroundColor: '#217efd' }}
                />
                :
                <img
                  src={item.icon}
                  alt={`${item.name} logo`}
                  className="w-6 h-6 mr-2"
                  // style={{ backgroundColor: '#217efd' }}
                />}
                <span className={`text-left flex-1 text-[14px] 2xl:text-[28px] 2xl:font-[550] ${activeComponent === item.name ? 'text-[#217efd]' : 'text-white'}`}>
                  {item.name}
                </span>
              </Button>
            </ListItem>
          ))}


          {/* <div
            className={`absolute left-0 bg-[#E9EEFF] transition-all duration-500 ease-in-out`}
            style={{
              top: `${highlightPosition + 12}px`,
              height: '50px',
              width: 'calc(100% - 20px)',
              marginRight: '0px',
              marginLeft: '0px',
              opacity: 1,
              borderTopLeftRadius: '0px', 
              borderTopRightRadius: '30px',
              borderBottomLeftRadius: '0px', 
              borderBottomRightRadius: '30px', 
            }}
          /> */}

          <div
            className={`absolute left-0 bg-[#E9EEFF] transition-all duration-500 ease-in-out
              ${highlightPosition + 12}px 
              h-[50px] 
              w-[calc(100%_-_20px)] 
              ml-[-20px] 
              opacity-1 
              rounded-tl-[0px] 
              rounded-tr-[30px] 
              rounded-bl-[0px] 
              rounded-br-[30px] 
              sm:h-[60px] sm:w-[calc(100%_-_30px)] sm:ml-[-10px] sm:rounded-tl-[10px] sm:rounded-bl-[10px]
              md:h-[70px] md:w-[calc(100%_-_40px)] md:ml-[-15px] md:rounded-tl-[20px] md:rounded-bl-[20px]
              lg:h-[80px] lg:w-[calc(100%_-_50px)] lg:ml-[-20px] lg:rounded-tl-[30px] lg:rounded-bl-[30px]
              xl:h-[50px] xl:w-[calc(100%_-_10%)] xl:ml-[0px] xl:rounded-tl-[0px] xl:rounded-bl-[0px]
              2xl:h-[50px] 2xl:w-[calc(100%_-_70px)] 2xl:ml-[-30px] 2xl:rounded-tl-[50px] 2xl:rounded-bl-[50px]`}
            style={{
              top: `${highlightPosition + 12}px`, // You may want to adjust this if necessary
            }}
          />

        </List>
      </div>
    </div>
  );
};

export default EsrDashboard;
