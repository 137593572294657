const carbonFootprint = {
  2024: {
    Jan: {
      1: {
        totalGHGEmissions: { value: 1500, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1260, unit: "tons CO2e" },
        'Building Emissions': { value: 2320, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 460, unit: "tons CO2e" },
        'Emission Reduction': { value: 10, unit: "tons CO2e" },
        'Carbon Offset': { value: 40, unit: "tons CO2e" },
        'Cumulative Emission': { value: 10, unit: "tons CO2e" },
      },
      15: {
        totalGHGEmissions: { value: 1600, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1653, unit: "tons CO2e" },
        'Building Emissions': { value: 1783, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 230, unit: "tons CO2e" },
        'Emission Reduction': { value: 20, unit: "tons CO2e" },
        'Carbon Offset': { value: 10, unit: "tons CO2e" },
        'Cumulative Emission': { value: 90, unit: "tons CO2e" },
      },
      25: {
        totalGHGEmissions: { value: 1700, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1098, unit: "tons CO2e" },
        'Building Emissions': { value: 390, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 60, unit: "tons CO2e" },
        'Emission Reduction': { value: 10, unit: "tons CO2e" },
        'Carbon Offset': { value: 30, unit: "tons CO2e" },
        'Cumulative Emission': { value: 40, unit: "tons CO2e" },
      },
    },
    Feb: {
      1: {
        totalGHGEmissions: { value: 1800, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1874, unit: "tons CO2e" },
        'Building Emissions': { value: 2312, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 232, unit: "tons CO2e" },
        'Emission Reduction': { value: 43, unit: "tons CO2e" },
        'Carbon Offset': { value: 34, unit: "tons CO2e" },
        'Cumulative Emission': { value: 54, unit: "tons CO2e" },
      },
      12: {
        totalGHGEmissions: { value: 1900, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1830, unit: "tons CO2e" },
        'Building Emissions': { value: 2981, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 209, unit: "tons CO2e" },
        'Emission Reduction': { value: 39, unit: "tons CO2e" },
        'Carbon Offset': { value: 74, unit: "tons CO2e" },
        'Cumulative Emission': { value: 29, unit: "tons CO2e" },
      },
      25: {
        totalGHGEmissions: { value: 1100, unit: "tons CO2e" },
        'Transportation Emissions': { value: 1887, unit: "tons CO2e" },
        'Building Emissions': { value: 237, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 12, unit: "tons CO2e" },
        'Emission Reduction': { value: 32, unit: "tons CO2e" },
        'Carbon Offset': { value: 23, unit: "tons CO2e" },
        'Cumulative Emission': { value: 20, unit: "tons CO2e" },
      },
    },
    Mar: {
      1: {
        totalGHGEmissions: { value: 1000, unit: "tons CO2e" },
        'Transportation Emissions': { value: 60, unit: "tons CO2e" },
        'Building Emissions': { value: 60, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 60, unit: "tons CO2e" },
        'Emission Reduction': { value: 60, unit: "tons CO2e" },
        'Carbon Offset': { value: 60, unit: "tons CO2e" },
        'Cumulative Emission': { value: 60, unit: "tons CO2e" },
      },
      12: {
        totalGHGEmissions: { value: 1200, unit: "tons CO2e" },
        'Transportation Emissions': { value: 60, unit: "tons CO2e" },
        'Building Emissions': { value: 60, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 60, unit: "tons CO2e" },
        'Emission Reduction': { value: 60, unit: "tons CO2e" },
        'Carbon Offset': { value: 60, unit: "tons CO2e" },
        'Cumulative Emission': { value: 60, unit: "tons CO2e" },
      },
      25: {
        totalGHGEmissions: { value: 1300, unit: "tons CO2e" },
        'Transportation Emissions': { value: 60, unit: "tons CO2e" },
        'Building Emissions': { value: 60, unit: "tons CO2e" },
        'Manufacturing Emissions': { value: 60, unit: "tons CO2e" },
        'Emission Reduction': { value: 60, unit: "tons CO2e" },
        'Carbon Offset': { value: 60, unit: "tons CO2e" },
        'Cumulative Emission': { value: 60, unit: "tons CO2e" },
      },
    },
Apr: {
  5: {
    totalGHGEmissions: { value: 1450, unit: "tons CO2e" },
    'Transportation Emissions': { value: 850, unit: "tons CO2e" },
    'Building Emissions': { value: 400, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 200, unit: "tons CO2e" },
    'Emission Reduction': { value: 20, unit: "tons CO2e" },
    'Carbon Offset': { value: 30, unit: "tons CO2e" },
    'Cumulative Emission': { value: 40, unit: "tons CO2e" },
  },
  16: {
    totalGHGEmissions: { value: 1380, unit: "tons CO2e" },
    'Transportation Emissions': { value: 820, unit: "tons CO2e" },
    'Building Emissions': { value: 360, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 210, unit: "tons CO2e" },
    'Emission Reduction': { value: 25, unit: "tons CO2e" },
    'Carbon Offset': { value: 20, unit: "tons CO2e" },
    'Cumulative Emission': { value: 30, unit: "tons CO2e" },
  },
  27: {
    totalGHGEmissions: { value: 1580, unit: "tons CO2e" },
    'Transportation Emissions': { value: 900, unit: "tons CO2e" },
    'Building Emissions': { value: 420, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
    'Emission Reduction': { value: 30, unit: "tons CO2e" },
    'Carbon Offset': { value: 40, unit: "tons CO2e" },
    'Cumulative Emission': { value: 35, unit: "tons CO2e" },
  },
},
May: {
  7: {
    totalGHGEmissions: { value: 1490, unit: "tons CO2e" },
    'Transportation Emissions': { value: 880, unit: "tons CO2e" },
    'Building Emissions': { value: 450, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 160, unit: "tons CO2e" },
    'Emission Reduction': { value: 15, unit: "tons CO2e" },
    'Carbon Offset': { value: 50, unit: "tons CO2e" },
    'Cumulative Emission': { value: 45, unit: "tons CO2e" },
  },
  18: {
    totalGHGEmissions: { value: 1340, unit: "tons CO2e" },
    'Transportation Emissions': { value: 840, unit: "tons CO2e" },
    'Building Emissions': { value: 360, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 140, unit: "tons CO2e" },
    'Emission Reduction': { value: 25, unit: "tons CO2e" },
    'Carbon Offset': { value: 30, unit: "tons CO2e" },
    'Cumulative Emission': { value: 35, unit: "tons CO2e" },
  },
  29: {
    totalGHGEmissions: { value: 1640, unit: "tons CO2e" },
    'Transportation Emissions': { value: 920, unit: "tons CO2e" },
    'Building Emissions': { value: 480, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 240, unit: "tons CO2e" },
    'Emission Reduction': { value: 20, unit: "tons CO2e" },
    'Carbon Offset': { value: 35, unit: "tons CO2e" },
    'Cumulative Emission': { value: 40, unit: "tons CO2e" },
  },
},
Jun: {
  3: {
    totalGHGEmissions: { value: 1620, unit: "tons CO2e" },
    'Transportation Emissions': { value: 890, unit: "tons CO2e" },
    'Building Emissions': { value: 470, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
    'Emission Reduction': { value: 20, unit: "tons CO2e" },
    'Carbon Offset': { value: 40, unit: "tons CO2e" },
    'Cumulative Emission': { value: 50, unit: "tons CO2e" },
  },
  15: {
    totalGHGEmissions: { value: 1470, unit: "tons CO2e" },
    'Transportation Emissions': { value: 860, unit: "tons CO2e" },
    'Building Emissions': { value: 410, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 220, unit: "tons CO2e" },
    'Emission Reduction': { value: 15, unit: "tons CO2e" },
    'Carbon Offset': { value: 45, unit: "tons CO2e" },
    'Cumulative Emission': { value: 40, unit: "tons CO2e" },
  },
  27: {
    totalGHGEmissions: { value: 1780, unit: "tons CO2e" },
    'Transportation Emissions': { value: 970, unit: "tons CO2e" },
    'Building Emissions': { value: 530, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
    'Emission Reduction': { value: 25, unit: "tons CO2e" },
    'Carbon Offset': { value: 60, unit: "tons CO2e" },
    'Cumulative Emission': { value: 55, unit: "tons CO2e" },
  },
},
Jul: {
  4: {
    totalGHGEmissions: { value: 1800, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1000, unit: "tons CO2e" },
    'Building Emissions': { value: 520, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
    'Emission Reduction': { value: 30, unit: "tons CO2e" },
    'Carbon Offset': { value: 45, unit: "tons CO2e" },
    'Cumulative Emission': { value: 60, unit: "tons CO2e" },
  },
  16: {
    totalGHGEmissions: { value: 1580, unit: "tons CO2e" },
    'Transportation Emissions': { value: 920, unit: "tons CO2e" },
    'Building Emissions': { value: 480, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
    'Emission Reduction': { value: 25, unit: "tons CO2e" },
    'Carbon Offset': { value: 50, unit: "tons CO2e" },
    'Cumulative Emission': { value: 45, unit: "tons CO2e" },
  },
  29: {
    totalGHGEmissions: { value: 1850, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1050, unit: "tons CO2e" },
    'Building Emissions': { value: 560, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 310, unit: "tons CO2e" },
    'Emission Reduction': { value: 35, unit: "tons CO2e" },
    'Carbon Offset': { value: 55, unit: "tons CO2e" },
    'Cumulative Emission': { value: 65, unit: "tons CO2e" },
  },
},
Aug: {
  2: {
    totalGHGEmissions: { value: 1720, unit: "tons CO2e" },
    'Transportation Emissions': { value: 950, unit: "tons CO2e" },
    'Building Emissions': { value: 510, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
    'Emission Reduction': { value: 25, unit: "tons CO2e" },
    'Carbon Offset': { value: 45, unit: "tons CO2e" },
    'Cumulative Emission': { value: 55, unit: "tons CO2e" },
  },
  14: {
    totalGHGEmissions: { value: 1550, unit: "tons CO2e" },
    'Transportation Emissions': { value: 880, unit: "tons CO2e" },
    'Building Emissions': { value: 470, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
    'Emission Reduction': { value: 30, unit: "tons CO2e" },
    'Carbon Offset': { value: 35, unit: "tons CO2e" },
    'Cumulative Emission': { value: 45, unit: "tons CO2e" },
  },
  27: {
    totalGHGEmissions: { value: 1780, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1020, unit: "tons CO2e" },
    'Building Emissions': { value: 510, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
    'Emission Reduction': { value: 35, unit: "tons CO2e" },
    'Carbon Offset': { value: 40, unit: "tons CO2e" },
    'Cumulative Emission': { value: 50, unit: "tons CO2e" },
  },
},
Sep: {
  3: {
    totalGHGEmissions: { value: 1820, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1020, unit: "tons CO2e" },
    'Building Emissions': { value: 530, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
    'Emission Reduction': { value: 40, unit: "tons CO2e" },
    'Carbon Offset': { value: 55, unit: "tons CO2e" },
    'Cumulative Emission': { value: 60, unit: "tons CO2e" },
  },
  15: {
    totalGHGEmissions: { value: 1600, unit: "tons CO2e" },
    'Transportation Emissions': { value: 900, unit: "tons CO2e" },
    'Building Emissions': { value: 450, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
    'Emission Reduction': { value: 30, unit: "tons CO2e" },
    'Carbon Offset': { value: 45, unit: "tons CO2e" },
    'Cumulative Emission': { value: 50, unit: "tons CO2e" },
  },
  27: {
    totalGHGEmissions: { value: 1900, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1060, unit: "tons CO2e" },
    'Building Emissions': { value: 580, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
    'Emission Reduction': { value: 45, unit: "tons CO2e" },
    'Carbon Offset': { value: 60, unit: "tons CO2e" },
    'Cumulative Emission': { value: 65, unit: "tons CO2e" },
  },
},
Oct: {
  5: {
    totalGHGEmissions: { value: 1940, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
    'Building Emissions': { value: 590, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
    'Emission Reduction': { value: 35, unit: "tons CO2e" },
    'Carbon Offset': { value: 60, unit: "tons CO2e" },
    'Cumulative Emission': { value: 70, unit: "tons CO2e" },
  },
  18: {
    totalGHGEmissions: { value: 1710, unit: "tons CO2e" },
    'Transportation Emissions': { value: 960, unit: "tons CO2e" },
    'Building Emissions': { value: 520, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 230, unit: "tons CO2e" },
    'Emission Reduction': { value: 45, unit: "tons CO2e" },
    'Carbon Offset': { value: 50, unit: "tons CO2e" },
    'Cumulative Emission': { value: 55, unit: "tons CO2e" },
  },
  30: {
    totalGHGEmissions: { value: 2000, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1080, unit: "tons CO2e" },
    'Building Emissions': { value: 620, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
    'Emission Reduction': { value: 50, unit: "tons CO2e" },
    'Carbon Offset': { value: 65, unit: "tons CO2e" },
    'Cumulative Emission': { value: 75, unit: "tons CO2e" },
  },
},
Nov: {
  8: {
    totalGHGEmissions: { value: 1980, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1120, unit: "tons CO2e" },
    'Building Emissions': { value: 600, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
    'Emission Reduction': { value: 40, unit: "tons CO2e" },
    'Carbon Offset': { value: 70, unit: "tons CO2e" },
    'Cumulative Emission': { value: 65, unit: "tons CO2e" },
  },
  19: {
    totalGHGEmissions: { value: 1820, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1000, unit: "tons CO2e" },
    'Building Emissions': { value: 570, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
    'Emission Reduction': { value: 30, unit: "tons CO2e" },
    'Carbon Offset': { value: 55, unit: "tons CO2e" },
    'Cumulative Emission': { value: 50, unit: "tons CO2e" },
  },
  27: {
    totalGHGEmissions: { value: 2100, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1140, unit: "tons CO2e" },
    'Building Emissions': { value: 650, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
    'Emission Reduction': { value: 55, unit: "tons CO2e" },
    'Carbon Offset': { value: 75, unit: "tons CO2e" },
    'Cumulative Emission': { value: 70, unit: "tons CO2e" },
  },
},
Dec: {
  1: {
    totalGHGEmissions: { value: 2050, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1160, unit: "tons CO2e" },
    'Building Emissions': { value: 600, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
    'Emission Reduction': { value: 50, unit: "tons CO2e" },
    'Carbon Offset': { value: 65, unit: "tons CO2e" },
    'Cumulative Emission': { value: 75, unit: "tons CO2e" },
  },
  15: {
    totalGHGEmissions: { value: 1890, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
    'Building Emissions': { value: 580, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
    'Emission Reduction': { value: 45, unit: "tons CO2e" },
    'Carbon Offset': { value: 55, unit: "tons CO2e" },
    'Cumulative Emission': { value: 70, unit: "tons CO2e" },
  },
  28: {
    totalGHGEmissions: { value: 2200, unit: "tons CO2e" },
    'Transportation Emissions': { value: 1200, unit: "tons CO2e" },
    'Building Emissions': { value: 700, unit: "tons CO2e" },
    'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
    'Emission Reduction': { value: 60, unit: "tons CO2e" },
    'Carbon Offset': { value: 80, unit: "tons CO2e" },
    'Cumulative Emission': { value: 80, unit: "tons CO2e" },
  },
}

},
2023:{
Jan: {
1: {
  totalGHGEmissions: { value: 2050, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 580, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 60, unit: "tons CO2e" },
  'Cumulative Emission': { value: 75, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 1910, unit: "tons CO2e" },
  'Transportation Emissions': { value: 980, unit: "tons CO2e" },
  'Building Emissions': { value: 560, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 240, unit: "tons CO2e" },
  'Emission Reduction': { value: 35, unit: "tons CO2e" },
  'Carbon Offset': { value: 55, unit: "tons CO2e" },
  'Cumulative Emission': { value: 50, unit: "tons CO2e" },
},
28: {
  totalGHGEmissions: { value: 2160, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1120, unit: "tons CO2e" },
  'Building Emissions': { value: 590, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
  'Emission Reduction': { value: 55, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 80, unit: "tons CO2e" },
},
},
Feb: {
5: {
  totalGHGEmissions: { value: 1870, unit: "tons CO2e" },
  'Transportation Emissions': { value: 930, unit: "tons CO2e" },
  'Building Emissions': { value: 540, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
  'Emission Reduction': { value: 30, unit: "tons CO2e" },
  'Carbon Offset': { value: 50, unit: "tons CO2e" },
  'Cumulative Emission': { value: 45, unit: "tons CO2e" },
},
14: {
  totalGHGEmissions: { value: 2040, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1150, unit: "tons CO2e" },
  'Building Emissions': { value: 610, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 40, unit: "tons CO2e" },
  'Carbon Offset': { value: 60, unit: "tons CO2e" },
  'Cumulative Emission': { value: 75, unit: "tons CO2e" },
},
28: {
  totalGHGEmissions: { value: 2200, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1200, unit: "tons CO2e" },
  'Building Emissions': { value: 640, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
  'Emission Reduction': { value: 55, unit: "tons CO2e" },
  'Carbon Offset': { value: 75, unit: "tons CO2e" },
  'Cumulative Emission': { value: 85, unit: "tons CO2e" },
},
},
Mar: {
1: {
  totalGHGEmissions: { value: 2050, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 590, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
  'Emission Reduction': { value: 45, unit: "tons CO2e" },
  'Carbon Offset': { value: 65, unit: "tons CO2e" },
  'Cumulative Emission': { value: 78, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 1960, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1020, unit: "tons CO2e" },
  'Building Emissions': { value: 570, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
  'Emission Reduction': { value: 30, unit: "tons CO2e" },
  'Carbon Offset': { value: 50, unit: "tons CO2e" },
  'Cumulative Emission': { value: 68, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2150, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1160, unit: "tons CO2e" },
  'Building Emissions': { value: 620, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
  'Emission Reduction': { value: 55, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 82, unit: "tons CO2e" },
},
},
Apr: {
5: {
  totalGHGEmissions: { value: 2000, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1080, unit: "tons CO2e" },
  'Building Emissions': { value: 610, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 65, unit: "tons CO2e" },
  'Cumulative Emission': { value: 77, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 1980, unit: "tons CO2e" },
  'Transportation Emissions': { value: 990, unit: "tons CO2e" },
  'Building Emissions': { value: 580, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 40, unit: "tons CO2e" },
  'Carbon Offset': { value: 58, unit: "tons CO2e" },
  'Cumulative Emission': { value: 75, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2130, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 630, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
  'Emission Reduction': { value: 52, unit: "tons CO2e" },
  'Carbon Offset': { value: 68, unit: "tons CO2e" },
  'Cumulative Emission': { value: 80, unit: "tons CO2e" },
},
},
May: {
5: {
  totalGHGEmissions: { value: 2000, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1080, unit: "tons CO2e" },
  'Building Emissions': { value: 610, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 65, unit: "tons CO2e" },
  'Cumulative Emission': { value: 77, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 1980, unit: "tons CO2e" },
  'Transportation Emissions': { value: 990, unit: "tons CO2e" },
  'Building Emissions': { value: 580, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 40, unit: "tons CO2e" },
  'Carbon Offset': { value: 58, unit: "tons CO2e" },
  'Cumulative Emission': { value: 75, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2130, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 630, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
  'Emission Reduction': { value: 52, unit: "tons CO2e" },
  'Carbon Offset': { value: 68, unit: "tons CO2e" },
  'Cumulative Emission': { value: 80, unit: "tons CO2e" },
},
},
Jun: {
1: {
  totalGHGEmissions: { value: 2150, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1180, unit: "tons CO2e" },
  'Building Emissions': { value: 620, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 72, unit: "tons CO2e" },
  'Cumulative Emission': { value: 85, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2000, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 600, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
  'Emission Reduction': { value: 40, unit: "tons CO2e" },
  'Carbon Offset': { value: 65, unit: "tons CO2e" },
  'Cumulative Emission': { value: 70, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2250, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1250, unit: "tons CO2e" },
  'Building Emissions': { value: 640, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
  'Emission Reduction': { value: 58, unit: "tons CO2e" },
  'Carbon Offset': { value: 78, unit: "tons CO2e" },
  'Cumulative Emission': { value: 90, unit: "tons CO2e" },
},
},
Jul: {
5: {
  totalGHGEmissions: { value: 2100, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1150, unit: "tons CO2e" },
  'Building Emissions': { value: 620, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
  'Emission Reduction': { value: 48, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 82, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 1950, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1050, unit: "tons CO2e" },
  'Building Emissions': { value: 590, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 250, unit: "tons CO2e" },
  'Emission Reduction': { value: 38, unit: "tons CO2e" },
  'Carbon Offset': { value: 60, unit: "tons CO2e" },
  'Cumulative Emission': { value: 75, unit: "tons CO2e" },
},
31: {
  totalGHGEmissions: { value: 2300, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1300, unit: "tons CO2e" },
  'Building Emissions': { value: 660, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 310, unit: "tons CO2e" },
  'Emission Reduction': { value: 60, unit: "tons CO2e" },
  'Carbon Offset': { value: 80, unit: "tons CO2e" },
  'Cumulative Emission': { value: 88, unit: "tons CO2e" },
},
},
Aug: {
1: {
  totalGHGEmissions: { value: 2200, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1200, unit: "tons CO2e" },
  'Building Emissions': { value: 630, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 75, unit: "tons CO2e" },
  'Cumulative Emission': { value: 85, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2050, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 610, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 42, unit: "tons CO2e" },
  'Carbon Offset': { value: 65, unit: "tons CO2e" },
  'Cumulative Emission': { value: 78, unit: "tons CO2e" },
},
31: {
  totalGHGEmissions: { value: 2350, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1300, unit: "tons CO2e" },
  'Building Emissions': { value: 680, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 320, unit: "tons CO2e" },
  'Emission Reduction': { value: 62, unit: "tons CO2e" },
  'Carbon Offset': { value: 85, unit: "tons CO2e" },
  'Cumulative Emission': { value: 92, unit: "tons CO2e" },
},
},
Sep: {
5: {
  totalGHGEmissions: { value: 2250, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1250, unit: "tons CO2e" },
  'Building Emissions': { value: 640, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
  'Emission Reduction': { value: 55, unit: "tons CO2e" },
  'Carbon Offset': { value: 78, unit: "tons CO2e" },
  'Cumulative Emission': { value: 88, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2100, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1150, unit: "tons CO2e" },
  'Building Emissions': { value: 610, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 45, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 78, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2400, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1350, unit: "tons CO2e" },
  'Building Emissions': { value: 700, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 330, unit: "tons CO2e" },
  'Emission Reduction': { value: 65, unit: "tons CO2e" },
  'Carbon Offset': { value: 85, unit: "tons CO2e" },
  'Cumulative Emission': { value: 95, unit: "tons CO2e" },
},
},
Oct: {
5: {
  totalGHGEmissions: { value: 2350, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1280, unit: "tons CO2e" },
  'Building Emissions': { value: 660, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 320, unit: "tons CO2e" },
  'Emission Reduction': { value: 60, unit: "tons CO2e" },
  'Carbon Offset': { value: 82, unit: "tons CO2e" },
  'Cumulative Emission': { value: 90, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2200, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1180, unit: "tons CO2e" },
  'Building Emissions': { value: 620, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 290, unit: "tons CO2e" },
  'Emission Reduction': { value: 50, unit: "tons CO2e" },
  'Carbon Offset': { value: 72, unit: "tons CO2e" },
  'Cumulative Emission': { value: 85, unit: "tons CO2e" },
},
31: {
  totalGHGEmissions: { value: 2450, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1380, unit: "tons CO2e" },
  'Building Emissions': { value: 710, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 350, unit: "tons CO2e" },
  'Emission Reduction': { value: 68, unit: "tons CO2e" },
  'Carbon Offset': { value: 88, unit: "tons CO2e" },
  'Cumulative Emission': { value: 98, unit: "tons CO2e" },
},
},
Nov: {
5: {
  totalGHGEmissions: { value: 2300, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1230, unit: "tons CO2e" },
  'Building Emissions': { value: 630, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 300, unit: "tons CO2e" },
  'Emission Reduction': { value: 55, unit: "tons CO2e" },
  'Carbon Offset': { value: 80, unit: "tons CO2e" },
  'Cumulative Emission': { value: 90, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2150, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1150, unit: "tons CO2e" },
  'Building Emissions': { value: 600, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 270, unit: "tons CO2e" },
  'Emission Reduction': { value: 48, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 80, unit: "tons CO2e" },
},
30: {
  totalGHGEmissions: { value: 2400, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1300, unit: "tons CO2e" },
  'Building Emissions': { value: 690, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 320, unit: "tons CO2e" },
  'Emission Reduction': { value: 65, unit: "tons CO2e" },
  'Carbon Offset': { value: 88, unit: "tons CO2e" },
  'Cumulative Emission': { value: 95, unit: "tons CO2e" },
},
},
Dec: {
5: {
  totalGHGEmissions: { value: 2250, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1200, unit: "tons CO2e" },
  'Building Emissions': { value: 630, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 280, unit: "tons CO2e" },
  'Emission Reduction': { value: 52, unit: "tons CO2e" },
  'Carbon Offset': { value: 75, unit: "tons CO2e" },
  'Cumulative Emission': { value: 85, unit: "tons CO2e" },
},
15: {
  totalGHGEmissions: { value: 2100, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1100, unit: "tons CO2e" },
  'Building Emissions': { value: 600, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 260, unit: "tons CO2e" },
  'Emission Reduction': { value: 45, unit: "tons CO2e" },
  'Carbon Offset': { value: 70, unit: "tons CO2e" },
  'Cumulative Emission': { value: 80, unit: "tons CO2e" },
},
31: {
  totalGHGEmissions: { value: 2350, unit: "tons CO2e" },
  'Transportation Emissions': { value: 1300, unit: "tons CO2e" },
  'Building Emissions': { value: 650, unit: "tons CO2e" },
  'Manufacturing Emissions': { value: 320, unit: "tons CO2e" },
  'Emission Reduction': { value: 65, unit: "tons CO2e" },
  'Carbon Offset': { value: 85, unit: "tons CO2e" },
  'Cumulative Emission': { value: 92, unit: "tons CO2e" },
},
},
},
}

export default carbonFootprint