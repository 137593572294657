import React, { useEffect } from 'react';
// import { Button, IconButton, Tooltip } from '@mui/material';
import { useDateFilter } from '../context/DateFilterContext'; 
// import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

const Header = ({ title, onRefresh }) => {
  const { filterType, setFilterType, year, setYear, monthRange, setMonthRange } = useDateFilter();

  // Array to map month numbers to names
  const monthNames = [
    '', 
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const calculateMonthRange = (filter) => {
    const currentMonth = new Date().getMonth() + 1;
    let startMonth = 1;
    let endMonth = 12;

    switch (filter) {
      case 'Quarterly':
        startMonth = currentMonth - 2 > 0 ? currentMonth - 2 : 1;
        endMonth = currentMonth;
        break;
      case 'Half-Yearly':
        startMonth = currentMonth - 5 > 0 ? currentMonth - 5 : 1;
        endMonth = currentMonth;
        break;
      case 'Yearly':
        startMonth = 1;
        endMonth = 12;
        break;
      case 'Monthly':
        startMonth = currentMonth;
        endMonth = currentMonth;
        break;
      default:
        break;
    }

    setMonthRange({ start: startMonth, end: endMonth });
  };

  // Automatically update the month range when the filter type changes
  useEffect(() => {
    calculateMonthRange(filterType);
  }, [filterType]);

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthRangeChange = (e) => {
    // console.log('e', e.target.value)
    const { name, value } = e.target;
    setMonthRange({ ...monthRange, [name]: value });
  };

  return (
    <div className="flex items-center mb-2 pl-16 2xl:pl-52">
      <div className="flex-grow flex items-center">
        <h1 className="text-3xl 2xl:text-5xl font-bold mr-4 ml-6 mb-4 2xl:mb-10 mt-4 2xl:mt-10">{title==='Overview'? 'ESR Overview' : title}</h1>
        {/* <Tooltip title={<span style={{ fontSize: '16px' }}>Refresh</span>} arrow>
        <IconButton style={{ marginRight: '20px' }} variant="contained" color="primary" onClick={onRefresh}>
          <CachedRoundedIcon sx={{fontSize:'34px', marginTop:'10px', backgroundColor:'#217EFD', color:'#ffff', borderRadius:'20px'}}/>
        </IconButton>
        </Tooltip> */}
      </div>
      <hr />

      {/* Filter Controls Container */}
      <div className="flex items-center justify-end mr-16 2xl:mr-24">

      {filterType !== 'Monthly' && (
          <div className="ml-4">
            <span className='text-[#747d92] text-[14px] 2xl:text-[28px] font-semibold'><span style={{fontWeight:'700'}}>Date Range:</span> {monthNames[monthRange.start]} - {monthNames[monthRange.end]}, {year}</span>
          </div>
        )}

        <select className="border rounded-lg p-2 mr-2 ml-4 2xl:text-[24px] " value={filterType} onChange={handleFilterChange}>
          <option value="Yearly">Yearly</option>
          <option value="Half-Yearly">Half-Yearly</option>
          <option value="Quarterly">Quarterly</option>
          <option value="Monthly">Monthly</option>
        </select>

        <select className="border rounded-lg p-2 mr-4 2xl:text-[24px]" value={year} onChange={handleYearChange}>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
        </select>

        {filterType === 'Monthly' && (
          <div className="flex">
            <select
              className="border rounded p-2 mr-2"
              name="start"
              value={monthRange.start}
              onChange={handleMonthRangeChange}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>

            <span className="self-center mr-2">to</span>

            <select
              className="border rounded p-2"
              name="end"
              value={monthRange.end}
              onChange={handleMonthRangeChange}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
