// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import esrReducer from './esrSlice'; // Import your esrReducer

const store = configureStore({
  reducer: {
    esr: esrReducer,
    // Add other reducers if needed
  },
});

export default store;
