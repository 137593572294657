const building ={
  "2023": {
    "January": {
      "7": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 410, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.5, unit: '%' },
        'Waste Recycling Rate': { value: 17.8, unit: '%' },
        'Indoor Air Quality Index': { value: 30, unit: '' }
      },
      "23": {
        energyUsage: { value: 29.5, unit: 'kWh/m²' },
        'Water Usage': { value: 21, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "February": {
      "5": {
        energyUsage: { value: 31, unit: 'kWh/m²' },
        'Water Usage': { value: 20, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.8, unit: '%' },
        'Waste Recycling Rate': { value: 18.2, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "19": {
        energyUsage: { value: 30.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 17.9, unit: '%' },
        'Indoor Air Quality Index': { value: 27.5, unit: '' }
      }
    },
    // Continue for remaining months in 2023
    "March": {
      "12": {
        energyUsage: { value: 33, unit: 'kWh/m²' },
        'Water Usage': { value: 18, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 390, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.1, unit: '%' },
        'Waste Recycling Rate': { value: 18.4, unit: '%' },
        'Indoor Air Quality Index': { value: 29.5, unit: '' }
      },
      "26": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18.1, unit: '%' },
        'Indoor Air Quality Index': { value: 28.2, unit: '' }
      }
    },
    "Apr": {
      "7": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 410, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.5, unit: '%' },
        'Waste Recycling Rate': { value: 17.8, unit: '%' },
        'Indoor Air Quality Index': { value: 30, unit: '' }
      },
      "23": {
        energyUsage: { value: 29.5, unit: 'kWh/m²' },
        'Water Usage': { value: 21, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "May": {
      "5": {
        energyUsage: { value: 31, unit: 'kWh/m²' },
        'Water Usage': { value: 20, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.8, unit: '%' },
        'Waste Recycling Rate': { value: 18.2, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "19": {
        energyUsage: { value: 30.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 17.9, unit: '%' },
        'Indoor Air Quality Index': { value: 27.5, unit: '' }
      }
    },
    // Continue for remaining months in 2023
    "Jun": {
      "12": {
        energyUsage: { value: 33, unit: 'kWh/m²' },
        'Water Usage': { value: 18, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 390, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.1, unit: '%' },
        'Waste Recycling Rate': { value: 18.4, unit: '%' },
        'Indoor Air Quality Index': { value: 29.5, unit: '' }
      },
      "26": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18.1, unit: '%' },
        'Indoor Air Quality Index': { value: 28.2, unit: '' }
      }
    },
    "Jul": {
      "7": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 410, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.5, unit: '%' },
        'Waste Recycling Rate': { value: 17.8, unit: '%' },
        'Indoor Air Quality Index': { value: 30, unit: '' }
      },
      "23": {
        energyUsage: { value: 29.5, unit: 'kWh/m²' },
        'Water Usage': { value: 21, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Aug": {
      "5": {
        energyUsage: { value: 31, unit: 'kWh/m²' },
        'Water Usage': { value: 20, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.8, unit: '%' },
        'Waste Recycling Rate': { value: 18.2, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "19": {
        energyUsage: { value: 30.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 17.9, unit: '%' },
        'Indoor Air Quality Index': { value: 27.5, unit: '' }
      }
    },
    // Continue for remaining months in 2023
    "Sep": {
      "12": {
        energyUsage: { value: 33, unit: 'kWh/m²' },
        'Water Usage': { value: 18, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 390, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.1, unit: '%' },
        'Waste Recycling Rate': { value: 18.4, unit: '%' },
        'Indoor Air Quality Index': { value: 29.5, unit: '' }
      },
      "26": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18.1, unit: '%' },
        'Indoor Air Quality Index': { value: 28.2, unit: '' }
      }
    },
    "Oct": {
      "7": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 410, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.5, unit: '%' },
        'Waste Recycling Rate': { value: 17.8, unit: '%' },
        'Indoor Air Quality Index': { value: 30, unit: '' }
      },
      "23": {
        energyUsage: { value: 29.5, unit: 'kWh/m²' },
        'Water Usage': { value: 21, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Nov": {
      "5": {
        energyUsage: { value: 31, unit: 'kWh/m²' },
        'Water Usage': { value: 20, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.8, unit: '%' },
        'Waste Recycling Rate': { value: 18.2, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "19": {
        energyUsage: { value: 30.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 17.9, unit: '%' },
        'Indoor Air Quality Index': { value: 27.5, unit: '' }
      }
    },
    // Continue for remaining months in 2023
    "Dec": {
      "12": {
        energyUsage: { value: 33, unit: 'kWh/m²' },
        'Water Usage': { value: 18, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 390, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.1, unit: '%' },
        'Waste Recycling Rate': { value: 18.4, unit: '%' },
        'Indoor Air Quality Index': { value: 29.5, unit: '' }
      },
      "26": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18.1, unit: '%' },
        'Indoor Air Quality Index': { value: 28.2, unit: '' }
      }
    },
  },
  "2024": {
    "January": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "February": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Mar": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "Apr": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "May": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "Jun": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Jul": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "Aug": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Sep": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "Oct": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
    "Nov": {
      "9": {
        energyUsage: { value: 31.5, unit: 'kWh/m²' },
        'Water Usage': { value: 19.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 405, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 7.9, unit: '%' },
        'Waste Recycling Rate': { value: 18.3, unit: '%' },
        'Indoor Air Quality Index': { value: 27.9, unit: '' }
      },
      "21": {
        energyUsage: { value: 30, unit: 'kWh/m²' },
        'Water Usage': { value: 20.5, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 28.5, unit: '' }
      }
    },
    // Continue for all months in 2024
    "Dec": {
      "3": {
        energyUsage: { value: 32, unit: 'kWh/m²' },
        'Water Usage': { value: 19.2, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 395, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8.2, unit: '%' },
        'Waste Recycling Rate': { value: 18.5, unit: '%' },
        'Indoor Air Quality Index': { value: 28, unit: '' }
      },
      "17": {
        energyUsage: { value: 30.8, unit: 'kWh/m²' },
        'Water Usage': { value: 20.8, unit: 'litres/Occupant' },
        'Carbon Emissions': { value: 400, unit: 'kg CO2' },
        'Renewable Energy Usage': { value: 8, unit: '%' },
        'Waste Recycling Rate': { value: 18, unit: '%' },
        'Indoor Air Quality Index': { value: 29, unit: '' }
      }
    },
  }

}

export default building