const compliance = {
  "2023": {
    "January": {
      "4": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.5, unit: '%' },
        'Carbon Emission Reductions': { value: 4.8, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "18": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "February": {
      "3": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      },
      "25": {
        totalCompliance: { value: 92.5, unit: '%' },
        'Number of Regulatory Violations': { value: 2, unit: '' },
        'Fines and Penalties': { value: 350, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.7, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.7, unit: '%' },
        'Waste Reduction': { value: 3.9, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "March": {
      "7": {
        totalCompliance: { value: 93.2, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      }
    },
    "April": {
      "5": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "15": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "May": {
      "4": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.5, unit: '%' },
        'Carbon Emission Reductions': { value: 4.8, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "18": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Jun": {
      "3": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      },
      "25": {
        totalCompliance: { value: 92.5, unit: '%' },
        'Number of Regulatory Violations': { value: 2, unit: '' },
        'Fines and Penalties': { value: 350, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.7, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.7, unit: '%' },
        'Waste Reduction': { value: 3.9, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "Jul": {
      "7": {
        totalCompliance: { value: 93.2, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      }
    },
    "Aug": {
      "5": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "15": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Sep": {
      "4": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.5, unit: '%' },
        'Carbon Emission Reductions': { value: 4.8, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "18": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Oct": {
      "3": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      },
      "25": {
        totalCompliance: { value: 92.5, unit: '%' },
        'Number of Regulatory Violations': { value: 2, unit: '' },
        'Fines and Penalties': { value: 350, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.7, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.7, unit: '%' },
        'Waste Reduction': { value: 3.9, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "Nov": {
      "7": {
        totalCompliance: { value: 93.2, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      }
    },
    "Dec": {
      "5": {
        totalCompliance: { value: 94, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.8, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "15": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
  },
  "2024": {
    "January": {
      "5": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "23": {
        totalCompliance: { value: 93.1, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      }
    },
    "February": {
      "8": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5.2, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "20": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "March": {
      "3": {
        totalCompliance: { value: 92.9, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8.3, unit: '%' },
        'Carbon Emission Reductions': { value: 5.3, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5.1, unit: '%' },
        'Waste Reduction': { value: 4.4, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Apr": {
      "5": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "23": {
        totalCompliance: { value: 93.1, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      }
    },
    "May": {
      "8": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5.2, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "20": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "Jun": {
      "3": {
        totalCompliance: { value: 92.9, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8.3, unit: '%' },
        'Carbon Emission Reductions': { value: 5.3, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5.1, unit: '%' },
        'Waste Reduction': { value: 4.4, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Jul": {
      "5": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "23": {
        totalCompliance: { value: 93.1, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      }
    },
    "Aug": {
      "8": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5.2, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "20": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "Sep": {
      "3": {
        totalCompliance: { value: 92.9, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8.3, unit: '%' },
        'Carbon Emission Reductions': { value: 5.3, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5.1, unit: '%' },
        'Waste Reduction': { value: 4.4, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
    "Oct": {
      "5": {
        totalCompliance: { value: 92, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 7.8, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 4.9, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "23": {
        totalCompliance: { value: 93.1, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8, unit: '%' },
        'Carbon Emission Reductions': { value: 5, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.8, unit: '%' }
      }
    },
    "Nov": {
      "8": {
        totalCompliance: { value: 93, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 290, unit: '$' },
        'Progress towards sustainability targets': { value: 8.1, unit: '%' },
        'Carbon Emission Reductions': { value: 5.2, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.3, unit: '%' },
        'Water Usage Reduction': { value: 2.9, unit: '%' }
      },
      "20": {
        totalCompliance: { value: 92.8, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 320, unit: '$' },
        'Progress towards sustainability targets': { value: 7.9, unit: '%' },
        'Carbon Emission Reductions': { value: 4.9, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.1, unit: '%' },
        'Water Usage Reduction': { value: 2.7, unit: '%' }
      }
    },
    "Dec": {
      "3": {
        totalCompliance: { value: 92.9, unit: '%' },
        'Number of Regulatory Violations': { value: 1, unit: '' },
        'Fines and Penalties': { value: 310, unit: '$' },
        'Progress towards sustainability targets': { value: 8.2, unit: '%' },
        'Carbon Emission Reductions': { value: 5.1, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5, unit: '%' },
        'Waste Reduction': { value: 4.2, unit: '%' },
        'Water Usage Reduction': { value: 3, unit: '%' }
      },
      "22": {
        totalCompliance: { value: 93.5, unit: '%' },
        'Number of Regulatory Violations': { value: 0, unit: '' },
        'Fines and Penalties': { value: 300, unit: '$' },
        'Progress towards sustainability targets': { value: 8.3, unit: '%' },
        'Carbon Emission Reductions': { value: 5.3, unit: 'tons' },
        'Energy Efficiency Targets': { value: 5.1, unit: '%' },
        'Waste Reduction': { value: 4.4, unit: '%' },
        'Water Usage Reduction': { value: 3.1, unit: '%' }
      }
    },
  }

}

export default compliance