import React, { useEffect, useState } from 'react';
import CardGrid from '../../components/cardGrid';
import { useSelector } from 'react-redux';

const EsrOverview = ({year, start, end}) => {

  // console.log('year, start, end', year, start, end)

  useEffect(()=>{
    setSelectedYear(year)
    setStartMonth(start)
    setEndMonth(end)
  },[year, start, end])

  const esrData = useSelector((state) => state.esr.data);

  // console.log('esrData----', esrData)

  const [selectedYear, setSelectedYear] = useState(year);
  const [startMonth, setStartMonth] = useState(start);
  const [endMonth, setEndMonth] = useState(end)


  const monthMap = {
    'January': 'Jan', 'Jan': 'Jan', 'january': 'Jan', 'jan': 'Jan',
    'February': 'Feb', 'Feb': 'Feb','february': 'Feb', 'feb': 'Feb',
    'March': 'Mar', 'Mar': 'Mar', 'march': 'Mar', 'mar': 'Mar',
    'April': 'Apr', 'Apr': 'Apr', 'april': 'Apr', 'apr': 'Apr',
    'May': 'May', 'may': 'May',
    'June': 'Jun', 'Jun': 'Jun', 'june': 'Jun', 'jun': 'Jun',
    'July': 'Jul', 'Jul': 'Jul', 'july': 'Jul', 'jul': 'Jul',
    'August': 'Aug', 'Aug': 'Aug', 'august': 'Aug', 'aug': 'Aug',
    'September': 'Sep', 'Sep': 'Sep', 'september': 'Sep', 'sep': 'Sep',
    'October': 'Oct', 'Oct': 'Oct', 'october': 'Oct', 'oct': 'Oct',
    'November': 'Nov', 'Nov': 'Nov', 'november': 'Nov', 'nov': 'Nov',
    'December': 'Dec', 'Dec': 'Dec', 'december': 'Dec', 'dec': 'Dec'
  };

  
  const getFilteredData = (kpiKey, totalKey, selectedYear, startMonth = 'Jan', endMonth = 'Dec') => {
    const yearData = esrData[kpiKey]?.[selectedYear];
    if (!yearData) return { total: 0, dynamicMetrics: {}, average: 0, totalUnit: '' }; // Initialize totalUnit
  
    let total = 0;
    let dynamicMetrics = {};
    let daysCount = 0;
    let totalUnit = ''; // Variable to store the unit of the total key
    let percentageTotalSum = 0; // To calculate the sum for percentage-based totals
    let percentageDaysCount = 0; // To track how many days had a percentage total
  
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
    const startMonthNormalized = monthMap[startMonth];
    const endMonthNormalized = monthMap[endMonth];
  
    const startMonthIndex = months.indexOf(startMonthNormalized);
    const endMonthIndex = months.indexOf(endMonthNormalized);
  
    // Separate counters for percentage-based metrics
    let percentageMetrics = {};
    let percentageMetricDaysCount = {}; // To track days for each percentage-based metric
  
    for (let i = startMonthIndex; i <= endMonthIndex; i++) {
      const monthName = months[i];
      const monthData = yearData[monthName] || yearData[Object.keys(yearData).find(m => monthMap[m] === monthName)];
      
      if (monthData) {
        // eslint-disable-next-line no-loop-func
        Object.values(monthData).forEach(dayData => {
          daysCount++;
          const totalEntry = dayData[totalKey];
  
          // Check if totalEntry exists and extract its value and unit
          if (totalEntry) {
            totalUnit = totalEntry.unit; // Set the unit for the total key
  
            if (totalEntry.unit === '%') {
              percentageTotalSum += totalEntry.value || 0;
              percentageDaysCount++;
            } else {
              total += totalEntry.value || 0;
            }
          }
  
          Object.keys(dayData).forEach(metricKey => {
            if (metricKey !== totalKey) {
              const metricValue = dayData[metricKey];
              if (metricValue) {
                if (metricValue.unit === '%') {
                  // Handle percentage-based metrics
                  if (!percentageMetrics[metricKey]) {
                    percentageMetrics[metricKey] = 0;
                    percentageMetricDaysCount[metricKey] = 0;
                  }
                  percentageMetrics[metricKey] += metricValue.value;
                  percentageMetricDaysCount[metricKey]++;
                } else {
                  // Handle regular metrics
                  dynamicMetrics[metricKey] = {
                    value: (dynamicMetrics[metricKey]?.value || 0) + metricValue.value,
                    unit: metricValue.unit
                  };
                }
              }
            }
          });
        });
      }
    }
  
    // Calculate the average for percentage-based metrics
    Object.keys(percentageMetrics).forEach(metricKey => {
      const count = percentageMetricDaysCount[metricKey];
      dynamicMetrics[metricKey] = {
        value: count > 0 ? percentageMetrics[metricKey] / count : 0,
        unit: '%'
      };
    });
  
    // If the total unit is a percentage, calculate its average
    if (totalUnit === '%') {
      total = percentageDaysCount > 0 ? percentageTotalSum / percentageDaysCount : 0;
    }
  
    return {
      total,
      dynamicMetrics,
      average: daysCount > 0 ? total / daysCount : 0,
      totalUnit // Return the unit of the total key
    };
  };
  


  
  
  
  
  
  
  const getMonthIndex = (month) => {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months.indexOf(month);
};


const getFilteredDataForGraph = (metric, year, startMonth = 'Jan', endMonth = 'Dec', fullData) => {
  // console.log('data inside---', year, startMonth, endMonth);

  const normalizeMonth = (monthName) => {
    const lowerCaseMonth = monthName.toLowerCase();
    return monthMap[lowerCaseMonth] || monthName; 
  };

  // Check if fullData is valid and contains the year
  if (!fullData || !fullData[year]) {
    console.error(`Year data not found for ${year} in fullData`, fullData);
    return { data: [], labels: [] };
  }

  const startMonthNormalized = normalizeMonth(startMonth);
  const endMonthNormalized = normalizeMonth(endMonth);

  if (!startMonthNormalized || !endMonthNormalized) {
    console.error("Invalid start or end month format");
    return { data: [], labels: [] };
  }

  const startIndex = getMonthIndex(startMonthNormalized);
  const endIndex = getMonthIndex(endMonthNormalized);

  if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
    console.error("Invalid month range");
    return { data: [], labels: [] };
  }

  const monthlyData = fullData[year];
  const aggregatedData = [];
  const labels = [];

  if (startMonthNormalized === endMonthNormalized) {
    const daysData = monthlyData[startMonthNormalized] ||
      monthlyData[Object.keys(monthlyData).find(m => normalizeMonth(m) === startMonthNormalized)];

    if (!daysData) {
      console.error(`No data found for month ${startMonthNormalized} in ${year}`);
      return { data: [], labels: [] };
    }

    let total = 0;
    let validDaysCount = 0;

    for (const [day, data] of Object.entries(daysData)) {
      if (data[metric] && data[metric].value !== undefined) {
        total += data[metric].value;
        validDaysCount++;
        labels.push(`Day ${day}`); 
      } else {
        console.warn(`Metric '${metric}' not found for ${year} ${startMonthNormalized} Day ${day}`);
      }
    }

    // Average if the metric unit is %
    if (validDaysCount > 0 && daysData[Object.keys(daysData)[0]][metric]?.unit === '%') {
      total = total / validDaysCount;
    }

    aggregatedData.push(total); 
  } else {
    for (const [month, days] of Object.entries(monthlyData)) {
      const monthNormalized = normalizeMonth(month);
      const monthIndex = getMonthIndex(monthNormalized);

      if (monthIndex >= startIndex && monthIndex <= endIndex) {
        let total = 0;
        let validDaysCount = 0;

        for (const [day, data] of Object.entries(days)) {
          if (data[metric] && data[metric].value !== undefined) {
            total += data[metric].value; 
            validDaysCount++;
          } else {
            console.warn(`Metric '${metric}' not found for ${year} ${month} Day ${day}`);
          }
        }

        // Average if the metric unit is %
        if (validDaysCount > 0 && days[Object.keys(days)[0]][metric]?.unit === '%') {
          total = total / validDaysCount;
        }

        aggregatedData.push(total); 
        labels.push(monthNormalized); 
      }
    }
  }

  return { data: aggregatedData, labels };
};



const energyGraphData = getFilteredDataForGraph('totalEnergyUsage',selectedYear,startMonth,endMonth,esrData.energyConsumption);
const carbonGraphData = getFilteredDataForGraph('totalGHGEmissions',selectedYear,startMonth,endMonth,esrData.carbonFootprint);
const waterGraphData = getFilteredDataForGraph('totalWaterConsumption',selectedYear,startMonth,endMonth,esrData.waterUsage);
const wasteGraphData = getFilteredDataForGraph('totalWasteGenerated',selectedYear,startMonth,endMonth,esrData.wasteManagement);
const airGraphData = getFilteredDataForGraph('averagePM25',selectedYear,startMonth,endMonth,esrData.airQuality);
const greenSpaceGraphData = getFilteredDataForGraph('totalArea',selectedYear,startMonth,endMonth,esrData.greenSpaces);
const transportationGraphData = getFilteredDataForGraph('publicTransitUsage',selectedYear,startMonth,endMonth,esrData.transportation);
const buildingGraphData = getFilteredDataForGraph('energyUsage',selectedYear,startMonth,endMonth,esrData.building);
const complianceGraphData = getFilteredDataForGraph('totalCompliance',selectedYear,startMonth,endMonth,esrData.compliance);


//GraphData function call  below

  const energyData = getFilteredData('energyConsumption', 'totalEnergyUsage', selectedYear, startMonth,endMonth,);
  const carbonData = getFilteredData('carbonFootprint', 'totalGHGEmissions', selectedYear, startMonth,endMonth,)
  const waterData = getFilteredData('waterUsage', 'totalWaterConsumption', selectedYear, startMonth,endMonth);
  const wasteData = getFilteredData('wasteManagement', 'totalWasteGenerated', selectedYear, startMonth,endMonth);
  const airQualityData = getFilteredData('airQuality', 'averagePM25', selectedYear, startMonth,endMonth);
  const greenSpaceData = getFilteredData('greenSpaces', 'totalArea', selectedYear, startMonth,endMonth);
  const transportationData = getFilteredData('transportation', 'publicTransitUsage', selectedYear, startMonth,endMonth);
  const buildingData = getFilteredData('building', 'energyUsage', selectedYear, startMonth,endMonth);
  const complianceData = getFilteredData('compliance', 'totalCompliance', selectedYear, startMonth,endMonth);


  // console.log('energy----', energyData)

  // console.log('energyGraphData----', energyGraphData)
  
  const cardsData = [
    {
      name: 'Energy',
      img: './energy.svg',
      color1: 'bg-[#7A40F2]',
      color2: 'bg-[#DECEFF]',
      textColor: 'text-[#7A40F2]',
      graphBackground: 'bg-[#F2ECFF]',
      lineColor: '#7A40F2',
      totalKey: 'Total Consumption',
      content: {
        'Total Consumption': {
          value: (typeof energyData.total === 'number' && !Number.isInteger(energyData.total)) 
            ? energyData.total.toFixed(2) 
            : (energyData.total || 'N/A'),
          unit: energyData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(energyData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: energyGraphData.data, // Add actual graph data here
      graphLabels: energyGraphData.labels // Add actual graph labels here
    },
    { 
      name: 'Carbon Footprint', 
      img: './carbon.svg', 
      color1: 'bg-[#040219]',
      color2: 'bg-[#C2C2C2]',
      textColor:'text-[#040219]',
      graphBackground:'bg-[#F9F6F6]',
      lineColor: '#040219',
      totalKey: 'Total Emission', 
      content: {
        'Total Emission': {
          value: (typeof carbonData.total === 'number' && !Number.isInteger(carbonData.total)) 
            ? carbonData.total.toFixed(2) 
            : (carbonData.total || 'N/A'),
          unit: carbonData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(carbonData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: carbonGraphData.data,
      graphLabels: carbonGraphData.labels,
    },
    { 
      name: 'Water', 
      img: './water.svg', 
      color1: 'bg-[#0F5E9C]',
      color2: 'bg-[#9ADFFF]',
      textColor:'text-[#0F5E9C]',
      graphBackground:'bg-[#E4F7FF]',
      lineColor: '#0F5E9C',
      totalKey: 'Total Usage', 
      content: {
        'Total Usage': {
          value: (typeof waterData.total === 'number' && !Number.isInteger(waterData.total)) 
            ? waterData.total.toFixed(2) 
            : (waterData.total || 'N/A'),
          unit: waterData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(waterData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: waterGraphData.data,
      graphLabels: waterGraphData.labels,
    },
    { 
      name: 'Waste', 
      img: './waste.svg', 
      color1: 'bg-[#6D431C]',
      color2: 'bg-[#F5C9A3]', 
      textColor:'text-[#6D431C]',
      graphBackground:'bg-[#FFF2E7]',
      lineColor: '#6D431C',
      totalKey: 'Total Waste', 
      content: {
        'Total Waste': {
          value: (typeof wasteData.total === 'number' && !Number.isInteger(wasteData.total)) 
            ? wasteData.total.toFixed(2) 
            : (wasteData.total || 'N/A'),
          unit: wasteData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(wasteData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: wasteGraphData.data,
      graphLabels: wasteGraphData.labels,
    },
    { 
      name: 'Air Quality', 
      img: './air.svg', 
      color1: 'bg-[#2F6D7F]',
      color2: 'bg-[#C9DBE7]', 
      textColor:'text-[#2F6D7F]',
      graphBackground:'bg-[#ECF7FD]',
      lineColor: '#2F6D7F',
      totalKey: 'PM2.5 Levels', 
      content: {
        'PM2.5 Levels': {
          value: (typeof airQualityData.total === 'number' && !Number.isInteger(airQualityData.total)) 
            ? airQualityData.total.toFixed(2) 
            : (airQualityData.total || 'N/A'),
          unit: airQualityData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(airQualityData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: airGraphData.data,
      graphLabels: airGraphData.labels,
    },
    { 
      name: 'Green Spaces', 
      img: './green.svg', 
      color1: 'bg-[#597C2B]',
      color2: 'bg-[#E2F17A]', 
      textColor:'text-[#597C2B]',
      graphBackground:'bg-[#FBFFE1]',
      lineColor: '#597C2B',
      totalKey: 'Total Area', 
      content: {
        'Total Area': {
          value: (typeof greenSpaceData.total === 'number' && !Number.isInteger(greenSpaceData.total)) 
            ? greenSpaceData.total.toFixed(2) 
            : (greenSpaceData.total || 'N/A'),
          unit: greenSpaceData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(greenSpaceData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: greenSpaceGraphData.data,
      graphLabels: greenSpaceGraphData.labels,
    },
    { 
      name: 'Transportation', 
      img: './transport.svg', 
      color1: 'bg-[#1C336E]',
      color2: 'bg-[#B9CDFF]', 
      textColor:'text-[#1C336E]',
      graphBackground:'bg-[#DCE6FF]',
      lineColor: '#1C336E',
      totalKey: 'Public Transport Usage', 
      content: {
        'Public Transport Usage': {
          value: (typeof transportationData.total === 'number' && !Number.isInteger(transportationData.total)) 
            ? transportationData.total.toFixed(2) 
            : (transportationData.total || 'N/A'),
          unit: transportationData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(transportationData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: transportationGraphData.data,
      graphLabels: transportationGraphData.labels,
    },
    { 
      name: 'Building', 
      img: './building.svg', 
      color1: 'bg-[#7F382F]',
      color2: 'bg-[#FFCFC9]', 
      textColor:'text-[#7F382F]',
      graphBackground:'bg-[#FFE7E4]',
      lineColor: '#7F382F',
      totalKey: 'Total Power Usage', 
      content: {
        'Total Power Usage': {
          value: (typeof buildingData.total === 'number' && !Number.isInteger(buildingData.total)) 
            ? buildingData.total.toFixed(2) 
            : (buildingData.total || 'N/A'),
          unit: buildingData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(buildingData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: buildingGraphData.data,
      graphLabels: buildingGraphData.labels,
    },
    { 
      name: 'Compliance', 
      img: './compliance.svg', 
      color1: 'bg-[#E46B00]',
      color2: 'bg-[#FFE6BC]', 
      textColor:'text-[#E46B00]',
      graphBackground:'bg-[#FFEFD4]',
      lineColor: '#E46B00',
      totalKey: 'Total Compliance Score', 
      content: {
        'Total Compliance Score': {
          value: (typeof complianceData.total === 'number' && !Number.isInteger(complianceData.total)) 
            ? complianceData.total.toFixed(2) 
            : (complianceData.total || 'N/A'),
          unit: complianceData.totalUnit // Assuming the unit for total consumption
        },
        ...Object.fromEntries(
          Object.entries(complianceData.dynamicMetrics).map(([metricName, metricValue]) => [
            metricName,
            {
              value: (typeof metricValue.value=== 'number' && !Number.isInteger(metricValue.value)) 
            ? metricValue.value.toFixed(2) 
            : (metricValue.value || 'N/A'),
              unit: (metricValue.unit)
            }
          ])
        )
      },
      graphData: complianceGraphData.data,
      graphLabels: complianceGraphData.labels,
    }
  ];

  // console.log('data-----==+++',cardsData[0]?.graphData)
  
  
  return (
    <div className="h-[88vh] overflow-hidden 2xl:pl-24">
      <CardGrid cardsData={cardsData} />
    </div>
  );
};

export default EsrOverview;
