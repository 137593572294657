import axios from 'axios';

const BASE_URL = 'https://api.yourclient.com'; // Replace with actual base URL

// API endpoints for each KPI
export const apiEndpoints = {
  esr: {
    energyConsumption: `${BASE_URL}/energy-consumption`,
    carbonFootprint: `${BASE_URL}/carbon-footprint`,
    waterUsage: `${BASE_URL}/water-usage`,
    wasteManagement: `${BASE_URL}/waste-management`,
    airQuality: `${BASE_URL}/air-quality`,
    greenSpaces: `${BASE_URL}/greenspaces`,
    transportation: `${BASE_URL}/transportation`,
    building: `${BASE_URL}/building`,
    compliance: `${BASE_URL}/compliance`,
  },
};

// Fetch KPI data
export const fetchKpiData = async (endpoint) => {
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};
