const airQuality = {
  2024:{
  January: {
    5: {
      averagePM25: { value: 4.5, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 16, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
    17: {
      averagePM25: { value: 5.1, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 14, unit: '' },
      'Compliance with Standards': { value: 35, unit: '%' },
    },
  },
  February: {
    3: {
      averagePM25: { value: 5.5, unit: 'µg/m³' },
      'NO2 Level': { value: 9, unit: 'µg/m³' },
      'Air Quality Index': { value: 13, unit: '' },
      'Compliance with Standards': { value: 28, unit: '%' },
    },
    20: {
      averagePM25: { value: 14.8, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 31, unit: '%' },
    },
  },
  March: {
    2: {
      averagePM25: { value: 15.3, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 33, unit: '%' },
    },
    15: {
      averagePM25: { value: 14.6, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 14, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
  },
  April: {
    5: {
      averagePM25: { value: 4.9, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 17, unit: '' },
      'Compliance with Standards': { value: 34, unit: '%' },
    },
    21: {
      averagePM25: { value: 5.0, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 36, unit: '%' },
    },
  },
  May: {
    8: {
      averagePM25: { value: 5.2, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 16, unit: '' },
      'Compliance with Standards': { value: 32, unit: '%' },
    },
    30: {
      averagePM25: { value: 4.7, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 14, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
  },
  June: {
    11: {
      averagePM25: { value: 5.6, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 18, unit: '' },
      'Compliance with Standards': { value: 35, unit: '%' },
    },
    27: {
      averagePM25: { value: 5.1, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 17, unit: '' },
      'Compliance with Standards': { value: 33, unit: '%' },
    },
  },
  July: {
    4: {
      averagePM25: { value: 4.4, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 28, unit: '%' },
    },
    19: {
      averagePM25: { value: 5.3, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 12, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
  },
  August: {
    12: {
      averagePM25: { value: 4.9, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 16, unit: '' },
      'Compliance with Standards': { value: 31, unit: '%' },
    },
    30: {
      averagePM25: { value: 5.0, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
  },
  September: {
    10: {
      averagePM25: { value: 5.2, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 18, unit: '' },
      'Compliance with Standards': { value: 34, unit: '%' },
    },
    25: {
      averagePM25: { value: 4.8, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 17, unit: '' },
      'Compliance with Standards': { value: 36, unit: '%' },
    },
  },
  October: {
    3: {
      averagePM25: { value: 5.1, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 14, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
    19: {
      averagePM25: { value: 5.3, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 16, unit: '' },
      'Compliance with Standards': { value: 28, unit: '%' },
    },
  },
  November: {
    7: {
      averagePM25: { value: 4.7, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 15, unit: '' },
      'Compliance with Standards': { value: 31, unit: '%' },
    },
    25: {
      averagePM25: { value: 5.4, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 14, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
  },
  December: {
    10: {
      averagePM25: { value: 5.2, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 16, unit: '' },
      'Compliance with Standards': { value: 33, unit: '%' },
    },
    30: {
      averagePM25: { value: 4.5, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 18, unit: '' },
      'Compliance with Standards': { value: 35, unit: '%' },
    },
  },
},
2023:{
  January: {
    6: {
      averagePM25: { value: 6.3, unit: 'µg/m³' },
      'NO2 Level': { value: 15, unit: 'µg/m³' },
      'Air Quality Index': { value: 20, unit: '' },
      'Compliance with Standards': { value: 25, unit: '%' },
    },
    22: {
      averagePM25: { value: 5.8, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 19, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
  },
  February: {
    9: {
      averagePM25: { value: 7.1, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 21, unit: '' },
      'Compliance with Standards': { value: 26, unit: '%' },
    },
    25: {
      averagePM25: { value: 5.4, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 17, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
  },
  March: {
    5: {
      averagePM25: { value: 12.2, unit: 'µg/m³' },
      'NO2 Level': { value: 15, unit: 'µg/m³' },
      'Air Quality Index': { value: 23, unit: '' },
      'Compliance with Standards': { value: 27, unit: '%' },
    },
    18: {
      averagePM25: { value: 5.9, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 22, unit: '' },
      'Compliance with Standards': { value: 28, unit: '%' },
    },
  },
  April: {
    1: {
      averagePM25: { value: 6.4, unit: 'µg/m³' },
      'NO2 Level': { value: 15, unit: 'µg/m³' },
      'Air Quality Index': { value: 25, unit: '' },
      'Compliance with Standards': { value: 23, unit: '%' },
    },
    23: {
      averagePM25: { value: 5.5, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 20, unit: '' },
      'Compliance with Standards': { value: 31, unit: '%' },
    },
  },
  May: {
    10: {
      averagePM25: { value: 6.1, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 24, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
    29: {
      averagePM25: { value: 5.2, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 19, unit: '' },
      'Compliance with Standards': { value: 25, unit: '%' },
    },
  },
  June: {
    12: {
      averagePM25: { value: 6.5, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 26, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
    30: {
      averagePM25: { value: 5.4, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 21, unit: '' },
      'Compliance with Standards': { value: 32, unit: '%' },
    },
  },
  July: {
    7: {
      averagePM25: { value: 6.8, unit: 'µg/m³' },
      'NO2 Level': { value: 15, unit: 'µg/m³' },
      'Air Quality Index': { value: 27, unit: '' },
      'Compliance with Standards': { value: 24, unit: '%' },
    },
    24: {
      averagePM25: { value: 5.7, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 20, unit: '' },
      'Compliance with Standards': { value: 33, unit: '%' },
    },
  },
  August: {
    15: {
      averagePM25: { value: 6.0, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 24, unit: '' },
      'Compliance with Standards': { value: 26, unit: '%' },
    },
    30: {
      averagePM25: { value: 5.9, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 22, unit: '' },
      'Compliance with Standards': { value: 29, unit: '%' },
    },
  },
  September: {
    5: {
      averagePM25: { value: 6.4, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 28, unit: '' },
      'Compliance with Standards': { value: 25, unit: '%' },
    },
    29: {
      averagePM25: { value: 5.1, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 23, unit: '' },
      'Compliance with Standards': { value: 27, unit: '%' },
    },
  },
  October: {
    2: {
      averagePM25: { value: 6.6, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 30, unit: '' },
      'Compliance with Standards': { value: 26, unit: '%' },
    },
    18: {
      averagePM25: { value: 5.3, unit: 'µg/m³' },
      'NO2 Level': { value: 12, unit: 'µg/m³' },
      'Air Quality Index': { value: 21, unit: '' },
      'Compliance with Standards': { value: 32, unit: '%' },
    },
  },
  November: {
    9: {
      averagePM25: { value: 6.1, unit: 'µg/m³' },
      'NO2 Level': { value: 10, unit: 'µg/m³' },
      'Air Quality Index': { value: 29, unit: '' },
      'Compliance with Standards': { value: 28, unit: '%' },
    },
    22: {
      averagePM25: { value: 5.4, unit: 'µg/m³' },
      'NO2 Level': { value: 13, unit: 'µg/m³' },
      'Air Quality Index': { value: 20, unit: '' },
      'Compliance with Standards': { value: 30, unit: '%' },
    },
  },
  December: {
    8: {
      averagePM25: { value: 20.3, unit: 'µg/m³' },
      'NO2 Level': { value: 14, unit: 'µg/m³' },
      'Air Quality Index': { value: 25, unit: '' },
      'Compliance with Standards': { value: 34, unit: '%' },
    },
    29: {
      averagePM25: { value: 5.6, unit: 'µg/m³' },
      'NO2 Level': { value: 11, unit: 'µg/m³' },
      'Air Quality Index': { value: 22, unit: '' },
      'Compliance with Standards': { value: 31, unit: '%' },
    },
  },
}
};

export default airQuality