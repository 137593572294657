import React from 'react';
import Card from './Card';

const CardGrid = ({cardsData}) => {

  // console.log('carddataaa---', cardsData, cardsData[0].graphData, cardsData[0].graphLabels)
  
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-3 gap-3 h-[97%] group mx-20 2xl:mx-32 mb-4">
  {cardsData?.map((card, index) => (
    <div 
      key={index} 
      className="relative transition-all duration-700 ease-in-out group-hover:blur-sm hover:!blur-none hover:z-50 "
    >
      <Card
          key={index}
          name={card.name}
          img={card.img}
          color1={card.color1}
          color2={card.color2}
          totalKey={card.totalKey}
          content={card.content}
          textColor={card.textColor}
          graphBackground={card.graphBackground}
          lineColor={card.lineColor}
          graphData={card.graphData}
          graphLabels={card.graphLabels}
        />
    </div>
  ))}
</div>

  );
};

export default CardGrid;
