import React, { createContext, useContext, useState } from 'react';

// Create a context
const DateFilterContext = createContext();

// Provider component
export const DateFilterProvider = ({ children }) => {
  const [filterType, setFilterType] = useState('Yearly');
  const [year, setYear] = useState('2024');
  const [monthRange, setMonthRange] = useState({ start: 'Jan', end: 'Dec' });

  return (
    <DateFilterContext.Provider
      value={{ filterType, setFilterType, year, setYear, monthRange, setMonthRange }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

// Hook to use the date filter context
export const useDateFilter = () => {
  return useContext(DateFilterContext);
};
