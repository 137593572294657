// src/pages/Settings.js
import React from 'react';
import { useThemeUpdate } from '../context/ThemeContext'; // Import your custom theme context
import { Typography, Container,Grid2 } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const colorOptions = [
  '#1976d2', '#dc004e', '#ff5722', '#4caf50', '#2196f3', '#fbc02d',
  '#7b1fa2', '#c2185b', '#d32f2f', '#0288d1', '#009688', '#43a047',
  '#f57c00', '#8e24aa'
];

const Settings = () => {
  const { setThemeColor } = useThemeUpdate();
  const [selectedColor, setSelectedColor] = React.useState('#1976d2'); // Default color

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setThemeColor(color);
  };

  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        Settings
      </Typography> */}
      <Typography variant="h6" gutterBottom>
        Select a color
      </Typography>
      <Grid2 container spacing={2}>
        {colorOptions?.map((color, index) => (
          <Grid2 item xs={3} sm={2} md={1} key={index}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                cursor: 'pointer',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                backgroundColor: color,
                border: `3px solid ${selectedColor === color ? '#000' : 'transparent'}`,
                margin: 'auto'
              }}
              onClick={() => handleColorSelect(color)}
            >
              {selectedColor === color && (
                <CheckCircleIcon
                  style={{
                    position: 'absolute',
                    color: '#fff',
                    fontSize: '24px'
                  }}
                />
              )}
            </div>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default Settings;
