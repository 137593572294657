const transportation = {
  "2023": {
    "January": {
      "5": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.5, unit: '' },
        'Reduction in CO2 Emission': { value: 750, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 280, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "17": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "February": {
      "9": {
        publicTransitUsage: { value: 3050, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.8, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 4, unit: '' }
      },
      "21": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.8, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Similar data structure for remaining months in 2023
    "March": {
      "7": {
        publicTransitUsage: { value: 3150, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 295, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "22": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 310, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      }
    },
    "Apr": {
      "5": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.5, unit: '' },
        'Reduction in CO2 Emission': { value: 750, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 280, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "17": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "May": {
      "9": {
        publicTransitUsage: { value: 3050, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.8, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 4, unit: '' }
      },
      "21": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.8, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Similar data structure for remaining months in 2023
    "Jun": {
      "7": {
        publicTransitUsage: { value: 3150, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 295, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "22": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 310, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      }
    },
    "Jul": {
      "5": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.5, unit: '' },
        'Reduction in CO2 Emission': { value: 750, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 280, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "17": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Aug": {
      "9": {
        publicTransitUsage: { value: 3050, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.8, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 4, unit: '' }
      },
      "21": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.8, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Similar data structure for remaining months in 2023
    "Sep": {
      "7": {
        publicTransitUsage: { value: 3150, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 295, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "22": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 310, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      }
    },
    "Oct": {
      "5": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.5, unit: '' },
        'Reduction in CO2 Emission': { value: 750, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 280, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "17": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Nov": {
      "9": {
        publicTransitUsage: { value: 3050, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.8, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 4, unit: '' }
      },
      "21": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.8, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Similar data structure for remaining months in 2023
    "Dec": {
      "7": {
        publicTransitUsage: { value: 3150, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 295, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "22": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 720, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.1, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 310, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      }
    },

  },
  "2024": {
    "January": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "February": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Mar": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "Apr": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "May": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "Jun": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Jul": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "Aug": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Sep": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "Oct": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    "Nov": {
      "8": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2.1, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.2, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 305, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      },
      "18": {
        publicTransitUsage: { value: 3200, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 740, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.5, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 315, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
    // Continue similar for all months till December 2024
    "Dec": {
      "11": {
        publicTransitUsage: { value: 3000, unit: 'trips' },
        'Kms of cycling lanes added': { value: 1.9, unit: '' },
        'Reduction in CO2 Emission': { value: 700, unit: 'kg' },
        'Average Fuel Efficiency': { value: 9.9, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 290, unit: '' },
        'EV charging stations installed': { value: 2, unit: '' }
      },
      "24": {
        publicTransitUsage: { value: 3100, unit: 'trips' },
        'Kms of cycling lanes added': { value: 2, unit: '' },
        'Reduction in CO2 Emission': { value: 730, unit: 'kg' },
        'Average Fuel Efficiency': { value: 10.3, unit: 'mpg' },
        'Carpool and Ride-sharing Trips': { value: 300, unit: '' },
        'EV charging stations installed': { value: 3, unit: '' }
      }
    },
  }

}

export default transportation