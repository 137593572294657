// src/components/Sidebar.js
import React from 'react';
import { Box, List, ListItem, ListItemIcon } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
// import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTheme } from '../context/ThemeContext'; // Import your custom theme context
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const { themeColor } = useTheme(); // Destructure the themeColor

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        position:'fixed',
        left:0,
        top:0,
        width: '4%',
        height: '100vh',
        backgroundColor: themeColor || '#1976d2', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <List style={{marginTop:'100px'}}>
      {/* <ListItem button onClick={() => handleNavigation('/home')}>
        <ListItemIcon>
          <HomeIcon style={{ color: '#fff', fontSize:"60px" }}  />
        </ListItemIcon>
      </ListItem> */}
      <ListItem button onClick={() => handleNavigation('/esr-Dashboard')}>
        <ListItemIcon>
            <DashboardIcon style={{ color: '#fff', fontSize: 52 }} /> {/* Set the fontSize directly here */}
        </ListItemIcon>
      </ListItem>
      {/* <ListItem button onClick={() => handleNavigation('/settings')}>
        <ListItemIcon>
          <SettingsIcon style={{ color: '#fff' }} fontSize="large" />
        </ListItemIcon>
      </ListItem> */}
    </List>
    </Box>
  );
};

export default Sidebar;
