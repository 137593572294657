const wasteManagement = {
  "2024": {
    "January": {
      "2": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 21, "unit": "%" },
        "Composted Waste": { "value": 3.5, "unit": "tons" },
        "Waste to Landfill": { "value": 4.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      },
      "18": {
        "totalWasteGenerated": { "value": 19, "unit": "tons" },
        "Recycled Waste": { "value": 14.5, "unit": "tons" },
        "Recycling Rate": { "value": 19, "unit": "%" },
        "Composted Waste": { "value": 2.8, "unit": "tons" },
        "Waste to Landfill": { "value": 5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.38, "unit": "tons" }
      }
    },
    "February": {
      "5": {
        "totalWasteGenerated": { "value": 21.5, "unit": "tons" },
        "Recycled Waste": { "value": 15.8, "unit": "tons" },
        "Recycling Rate": { "value": 20, "unit": "%" },
        "Composted Waste": { "value": 3.2, "unit": "tons" },
        "Waste to Landfill": { "value": 5.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.43, "unit": "tons" }
      },
      "19": {
        "totalWasteGenerated": { "value": 20, "unit": "tons" },
        "Recycled Waste": { "value": 14.8, "unit": "tons" },
        "Recycling Rate": { "value": 19.5, "unit": "%" },
        "Composted Waste": { "value": 2.9, "unit": "tons" },
        "Waste to Landfill": { "value": 4.8, "unit": "tons" },
        "Hazardous Waste": { "value": 0.55, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    },
    "March": {
      "3": {
        "totalWasteGenerated": { "value": 21.2, "unit": "tons" },
        "Recycled Waste": { "value": 15.6, "unit": "tons" },
        "Recycling Rate": { "value": 20.5, "unit": "%" },
        "Composted Waste": { "value": 3.1, "unit": "tons" },
        "Waste to Landfill": { "value": 4.9, "unit": "tons" },
        "Hazardous Waste": { "value": 0.45, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      },
      "23": {
        "totalWasteGenerated": { "value": 20.8, "unit": "tons" },
        "Recycled Waste": { "value": 15.2, "unit": "tons" },
        "Recycling Rate": { "value": 20.8, "unit": "%" },
        "Composted Waste": { "value": 3.3, "unit": "tons" },
        "Waste to Landfill": { "value": 5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.48, "unit": "tons" },
        "Waste Diversion": { "value": 0.43, "unit": "tons" }
      }
    },
    "April": {
      "8": {
        "totalWasteGenerated": { "value": 19.5, "unit": "tons" },
        "Recycled Waste": { "value": 14.9, "unit": "tons" },
        "Recycling Rate": { "value": 19, "unit": "%" },
        "Composted Waste": { "value": 2.7, "unit": "tons" },
        "Waste to Landfill": { "value": 5.1, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.39, "unit": "tons" }
      },
      "25": {
        "totalWasteGenerated": { "value": 21, "unit": "tons" },
        "Recycled Waste": { "value": 15.3, "unit": "tons" },
        "Recycling Rate": { "value": 20.3, "unit": "%" },
        "Composted Waste": { "value": 3.4, "unit": "tons" },
        "Waste to Landfill": { "value": 4.7, "unit": "tons" },
        "Hazardous Waste": { "value": 0.42, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    },
    "May": {
      "6": {
        "totalWasteGenerated": { "value": 20.2, "unit": "tons" },
        "Recycled Waste": { "value": 14.8, "unit": "tons" },
        "Recycling Rate": { "value": 19.5, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 5.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.39, "unit": "tons" }
      },
      "27": {
        "totalWasteGenerated": { "value": 21.5, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 21, "unit": "%" },
        "Composted Waste": { "value": 3.6, "unit": "tons" },
        "Waste to Landfill": { "value": 4.6, "unit": "tons" },
        "Hazardous Waste": { "value": 0.55, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      }
    },
    "June": {
      "7": {
        "totalWasteGenerated": { "value": 20.9, "unit": "tons" },
        "Recycled Waste": { "value": 15.4, "unit": "tons" },
        "Recycling Rate": { "value": 20.5, "unit": "%" },
        "Composted Waste": { "value": 3.1, "unit": "tons" },
        "Waste to Landfill": { "value": 4.8, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.43, "unit": "tons" }
      },
      "28": {
        "totalWasteGenerated": { "value": 19.8, "unit": "tons" },
        "Recycled Waste": { "value": 14.6, "unit": "tons" },
        "Recycling Rate": { "value": 19, "unit": "%" },
        "Composted Waste": { "value": 3.3, "unit": "tons" },
        "Waste to Landfill": { "value": 5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.39, "unit": "tons" }
      }
    },
    "July": {
      "9": {
        "totalWasteGenerated": { "value": 21.7, "unit": "tons" },
        "Recycled Waste": { "value": 15.9, "unit": "tons" },
        "Recycling Rate": { "value": 21.2, "unit": "%" },
        "Composted Waste": { "value": 3.5, "unit": "tons" },
        "Waste to Landfill": { "value": 4.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.58, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      },
      "20": {
        "totalWasteGenerated": { "value": 20.1, "unit": "tons" },
        "Recycled Waste": { "value": 14.9, "unit": "tons" },
        "Recycling Rate": { "value": 19.6, "unit": "%" },
        "Composted Waste": { "value": 3.2, "unit": "tons" },
        "Waste to Landfill": { "value": 5.1, "unit": "tons" },
        "Hazardous Waste": { "value": 0.47, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    },
    "August": {
      "5": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16.1, "unit": "tons" },
        "Recycling Rate": { "value": 21.5, "unit": "%" },
        "Composted Waste": { "value": 3.4, "unit": "tons" },
        "Waste to Landfill": { "value": 4.9, "unit": "tons" },
        "Hazardous Waste": { "value": 0.52, "unit": "tons" },
        "Waste Diversion": { "value": 0.44, "unit": "tons" }
      },
      "23": {
        "totalWasteGenerated": { "value": 19.9, "unit": "tons" },
        "Recycled Waste": { "value": 14.7, "unit": "tons" },
        "Recycling Rate": { "value": 19.2, "unit": "%" },
        "Composted Waste": { "value": 3.3, "unit": "tons" },
        "Waste to Landfill": { "value": 5.3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.48, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "September": {
      "7": {
        "totalWasteGenerated": { "value": 20.5, "unit": "tons" },
        "Recycled Waste": { "value": 15.3, "unit": "tons" },
        "Recycling Rate": { "value": 20.5, "unit": "%" },
        "Composted Waste": { "value": 3.2, "unit": "tons" },
        "Waste to Landfill": { "value": 5.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.43, "unit": "tons" }
      },
      "24": {
        "totalWasteGenerated": { "value": 19.7, "unit": "tons" },
        "Recycled Waste": { "value": 14.5, "unit": "tons" },
        "Recycling Rate": { "value": 19, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 4.7, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "October": {
      "10": {
        "totalWasteGenerated": { "value": 21.6, "unit": "tons" },
        "Recycled Waste": { "value": 15.7, "unit": "tons" },
        "Recycling Rate": { "value": 21, "unit": "%" },
        "Composted Waste": { "value": 3.5, "unit": "tons" },
        "Waste to Landfill": { "value": 5.1, "unit": "tons" },
        "Hazardous Waste": { "value": 0.47, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      },
      "28": {
        "totalWasteGenerated": { "value": 20.2, "unit": "tons" },
        "Recycled Waste": { "value": 14.6, "unit": "tons" },
        "Recycling Rate": { "value": 19.4, "unit": "%" },
        "Composted Waste": { "value": 3.1, "unit": "tons" },
        "Waste to Landfill": { "value": 5.3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.52, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "November": {
      "6": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 15.8, "unit": "tons" },
        "Recycling Rate": { "value": 20.6, "unit": "%" },
        "Composted Waste": { "value": 3.6, "unit": "tons" },
        "Waste to Landfill": { "value": 5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.55, "unit": "tons" },
        "Waste Diversion": { "value": 0.43, "unit": "tons" }
      },
      "27": {
        "totalWasteGenerated": { "value": 20.4, "unit": "tons" },
        "Recycled Waste": { "value": 14.9, "unit": "tons" },
        "Recycling Rate": { "value": 19.8, "unit": "%" },
        "Composted Waste": { "value": 3.2, "unit": "tons" },
        "Waste to Landfill": { "value": 5.4, "unit": "tons" },
        "Hazardous Waste": { "value": 0.46, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    },
    "December": {
      "7": {
        "totalWasteGenerated": { "value": 21.9, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 20.9, "unit": "%" },
        "Composted Waste": { "value": 3.7, "unit": "tons" },
        "Waste to Landfill": { "value": 5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.54, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      },
      "28": {
        "totalWasteGenerated": { "value": 20.6, "unit": "tons" },
        "Recycled Waste": { "value": 14.5, "unit": "tons" },
        "Recycling Rate": { "value": 19.2, "unit": "%" },
        "Composted Waste": { "value": 3.4, "unit": "tons" },
        "Waste to Landfill": { "value": 5.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.45, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    }
  },
  "2023": {
    "January": {
      "5": {
        "totalWasteGenerated": { "value": 20, "unit": "tons" },
        "Recycled Waste": { "value": 15, "unit": "tons" },
        "Recycling Rate": { "value": 75, "unit": "%" },
        "Composted Waste": { "value": 2, "unit": "tons" },
        "Waste to Landfill": { "value": 3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.35, "unit": "tons" }
      },
      "20": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 72.73, "unit": "%" },
        "Composted Waste": { "value": 2.5, "unit": "tons" },
        "Waste to Landfill": { "value": 3.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "February": {
      "10": {
        "totalWasteGenerated": { "value": 19, "unit": "tons" },
        "Recycled Waste": { "value": 14, "unit": "tons" },
        "Recycling Rate": { "value": 73.68, "unit": "%" },
        "Composted Waste": { "value": 2.3, "unit": "tons" },
        "Waste to Landfill": { "value": 4, "unit": "tons" },
        "Hazardous Waste": { "value": 0.7, "unit": "tons" },
        "Waste Diversion": { "value": 0.37, "unit": "tons" }
      },
      "25": {
        "totalWasteGenerated": { "value": 21, "unit": "tons" },
        "Recycled Waste": { "value": 15.5, "unit": "tons" },
        "Recycling Rate": { "value": 73.81, "unit": "%" },
        "Composted Waste": { "value": 2.8, "unit": "tons" },
        "Waste to Landfill": { "value": 3.7, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.42, "unit": "tons" }
      }
    },
    "March": {
      "12": {
        "totalWasteGenerated": { "value": 23, "unit": "tons" },
        "Recycled Waste": { "value": 17, "unit": "tons" },
        "Recycling Rate": { "value": 73.91, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.39, "unit": "tons" }
      },
      "30": {
        "totalWasteGenerated": { "value": 22.5, "unit": "tons" },
        "Recycled Waste": { "value": 16.5, "unit": "tons" },
        "Recycling Rate": { "value": 73.33, "unit": "%" },
        "Composted Waste": { "value": 2.7, "unit": "tons" },
        "Waste to Landfill": { "value": 3.3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.3, "unit": "tons" },
        "Waste Diversion": { "value": 0.41, "unit": "tons" }
      }
    },
    "April": {
      "15": {
        "totalWasteGenerated": { "value": 24, "unit": "tons" },
        "Recycled Waste": { "value": 18, "unit": "tons" },
        "Recycling Rate": { "value": 75, "unit": "%" },
        "Composted Waste": { "value": 3.5, "unit": "tons" },
        "Waste to Landfill": { "value": 2.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.7, "unit": "tons" },
        "Waste Diversion": { "value": 0.45, "unit": "tons" }
      },
      "28": {
        "totalWasteGenerated": { "value": 20, "unit": "tons" },
        "Recycled Waste": { "value": 14.5, "unit": "tons" },
        "Recycling Rate": { "value": 72.5, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.38, "unit": "tons" }
      }
    },
    "May": {
      "5": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 72.73, "unit": "%" },
        "Composted Waste": { "value": 2.9, "unit": "tons" },
        "Waste to Landfill": { "value": 3.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.39, "unit": "tons" }
      },
      "25": {
        "totalWasteGenerated": { "value": 21, "unit": "tons" },
        "Recycled Waste": { "value": 15.5, "unit": "tons" },
        "Recycling Rate": { "value": 73.81, "unit": "%" },
        "Composted Waste": { "value": 3.1, "unit": "tons" },
        "Waste to Landfill": { "value": 2.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "June": {
      "10": {
        "totalWasteGenerated": { "value": 23.5, "unit": "tons" },
        "Recycled Waste": { "value": 17, "unit": "tons" },
        "Recycling Rate": { "value": 72.34, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 3.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.44, "unit": "tons" }
      },
      "30": {
        "totalWasteGenerated": { "value": 20, "unit": "tons" },
        "Recycled Waste": { "value": 14, "unit": "tons" },
        "Recycling Rate": { "value": 70, "unit": "%" },
        "Composted Waste": { "value": 2.8, "unit": "tons" },
        "Waste to Landfill": { "value": 3.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.35, "unit": "tons" }
      }
    },
    "July": {
      "12": {
        "totalWasteGenerated": { "value": 25, "unit": "tons" },
        "Recycled Waste": { "value": 18, "unit": "tons" },
        "Recycling Rate": { "value": 72, "unit": "%" },
        "Composted Waste": { "value": 4, "unit": "tons" },
        "Waste to Landfill": { "value": 3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.8, "unit": "tons" },
        "Waste Diversion": { "value": 0.48, "unit": "tons" }
      },
      "31": {
        "totalWasteGenerated": { "value": 24, "unit": "tons" },
        "Recycled Waste": { "value": 17, "unit": "tons" },
        "Recycling Rate": { "value": 70.83, "unit": "%" },
        "Composted Waste": { "value": 4.5, "unit": "tons" },
        "Waste to Landfill": { "value": 2.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.9, "unit": "tons" },
        "Waste Diversion": { "value": 0.47, "unit": "tons" }
      }
    },
    "August": {
      "15": {
        "totalWasteGenerated": { "value": 26, "unit": "tons" },
        "Recycled Waste": { "value": 19, "unit": "tons" },
        "Recycling Rate": { "value": 73.08, "unit": "%" },
        "Composted Waste": { "value": 4.2, "unit": "tons" },
        "Waste to Landfill": { "value": 2.3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.49, "unit": "tons" }
      },
      "31": {
        "totalWasteGenerated": { "value": 24, "unit": "tons" },
        "Recycled Waste": { "value": 18, "unit": "tons" },
        "Recycling Rate": { "value": 75, "unit": "%" },
        "Composted Waste": { "value": 4, "unit": "tons" },
        "Waste to Landfill": { "value": 1.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.5, "unit": "tons" }
      }
    },
    "September": {
      "10": {
        "totalWasteGenerated": { "value": 23, "unit": "tons" },
        "Recycled Waste": { "value": 17, "unit": "tons" },
        "Recycling Rate": { "value": 73.91, "unit": "%" },
        "Composted Waste": { "value": 3.8, "unit": "tons" },
        "Waste to Landfill": { "value": 2.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.7, "unit": "tons" },
        "Waste Diversion": { "value": 0.46, "unit": "tons" }
      },
      "30": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 72.73, "unit": "%" },
        "Composted Waste": { "value": 3.2, "unit": "tons" },
        "Waste to Landfill": { "value": 2.2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.6, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      }
    },
    "October": {
      "5": {
        "totalWasteGenerated": { "value": 21, "unit": "tons" },
        "Recycled Waste": { "value": 15, "unit": "tons" },
        "Recycling Rate": { "value": 71.43, "unit": "%" },
        "Composted Waste": { "value": 3.6, "unit": "tons" },
        "Waste to Landfill": { "value": 2.4, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.38, "unit": "tons" }
      },
      "31": {
        "totalWasteGenerated": { "value": 24, "unit": "tons" },
        "Recycled Waste": { "value": 18, "unit": "tons" },
        "Recycling Rate": { "value": 75, "unit": "%" },
        "Composted Waste": { "value": 4.5, "unit": "tons" },
        "Waste to Landfill": { "value": 1.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.5, "unit": "tons" }
      }
    },
    "November": {
      "10": {
        "totalWasteGenerated": { "value": 19, "unit": "tons" },
        "Recycled Waste": { "value": 14, "unit": "tons" },
        "Recycling Rate": { "value": 73.68, "unit": "%" },
        "Composted Waste": { "value": 2.2, "unit": "tons" },
        "Waste to Landfill": { "value": 2.3, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.35, "unit": "tons" }
      },
      "30": {
        "totalWasteGenerated": { "value": 20, "unit": "tons" },
        "Recycled Waste": { "value": 15, "unit": "tons" },
        "Recycling Rate": { "value": 75, "unit": "%" },
        "Composted Waste": { "value": 3, "unit": "tons" },
        "Waste to Landfill": { "value": 2, "unit": "tons" },
        "Hazardous Waste": { "value": 0.4, "unit": "tons" },
        "Waste Diversion": { "value": 0.46, "unit": "tons" }
      }
    },
    "December": {
      "15": {
        "totalWasteGenerated": { "value": 22, "unit": "tons" },
        "Recycled Waste": { "value": 16, "unit": "tons" },
        "Recycling Rate": { "value": 72.73, "unit": "%" },
        "Composted Waste": { "value": 3.5, "unit": "tons" },
        "Waste to Landfill": { "value": 2.5, "unit": "tons" },
        "Hazardous Waste": { "value": 0.3, "unit": "tons" },
        "Waste Diversion": { "value": 0.4, "unit": "tons" }
      },
      "31": {
        "totalWasteGenerated": { "value": 23, "unit": "tons" },
        "Recycled Waste": { "value": 17, "unit": "tons" },
        "Recycling Rate": { "value": 73.91, "unit": "%" },
        "Composted Waste": { "value": 3.8, "unit": "tons" },
        "Waste to Landfill": { "value": 2.1, "unit": "tons" },
        "Hazardous Waste": { "value": 0.5, "unit": "tons" },
        "Waste Diversion": { "value": 0.48, "unit": "tons" }
      }
    }
  }
}


  

export default wasteManagement