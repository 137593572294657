const greenSpaces = {
  "2023": {
    "January": {
      "5": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 45, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "19": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.5, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 3.9, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      }
    },
    "February": {
      "7": {
        totalArea: { value: 198, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.2, unit: '%' },
        'Number of Trees planted': { value: 300, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      },
      "22": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 49, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    // Similar data structure for remaining months in 2023
    "March": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    "April": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    "May": {
      "5": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 45, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "19": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.5, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 3.9, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      }
    },
    "June": {
      "7": {
        totalArea: { value: 198, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.2, unit: '%' },
        'Number of Trees planted': { value: 300, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      },
      "22": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 49, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    // Similar data structure for remaining months in 2023
    "July": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    "Aug": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    "Sep": {
      "5": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 45, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "19": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.5, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 3.9, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      }
    },
    "Oct": {
      "7": {
        totalArea: { value: 198, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.2, unit: '%' },
        'Number of Trees planted': { value: 300, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      },
      "22": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 49, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    // Similar data structure for remaining months in 2023
    "Nov": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
    "Dec": {
      "8": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.1, unit: '%' },
        'Number of Trees planted': { value: 305, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 990, unit: 'hectares' },
        'Endangered species population': { value: 57, unit: '' },
        'Areas affected by Invasive Species': { value: 195, unit: 'hectares' }
      },
      "18": {
        totalArea: { value: 197, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.9, unit: '%' },
        'Number of Trees planted': { value: 298, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 980, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      }
    },
  },
  "2024": {
    "January": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "February": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
    "Mar": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "Apr": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
    "May": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "Jun": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
    "Jul": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "Aug": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
    "Sep": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "Oct": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
    "Nov": {
      "10": {
        totalArea: { value: 195, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 9.8, unit: '%' },
        'Number of Trees planted': { value: 280, unit: '' },
        'Green space per capita': { value: 4, unit: 'm²/person' },
        'Number of native Species': { value: 48, unit: '' },
        'Habitat Conservation Area': { value: 970, unit: 'hectares' },
        'Endangered species population': { value: 55, unit: '' },
        'Areas affected by Invasive Species': { value: 190, unit: 'hectares' }
      },
      "23": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 290, unit: '' },
        'Green space per capita': { value: 4.1, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 60, unit: '' },
        'Areas affected by Invasive Species': { value: 200, unit: 'hectares' }
      }
    },
    // Continue similar for all months till December 2024
    "Dec": {
      "12": {
        totalArea: { value: 199, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10.3, unit: '%' },
        'Number of Trees planted': { value: 315, unit: '' },
        'Green space per capita': { value: 4.3, unit: 'm²/person' },
        'Number of native Species': { value: 52, unit: '' },
        'Habitat Conservation Area': { value: 995, unit: 'hectares' },
        'Endangered species population': { value: 59, unit: '' },
        'Areas affected by Invasive Species': { value: 210, unit: 'hectares' }
      },
      "26": {
        totalArea: { value: 200, unit: 'hectares' },
        'Percentage of Urban Area Covered by Green Space': { value: 10, unit: '%' },
        'Number of Trees planted': { value: 310, unit: '' },
        'Green space per capita': { value: 4.2, unit: 'm²/person' },
        'Number of native Species': { value: 50, unit: '' },
        'Habitat Conservation Area': { value: 1000, unit: 'hectares' },
        'Endangered species population': { value: 58, unit: '' },
        'Areas affected by Invasive Species': { value: 205, unit: 'hectares' }
      }
    },
  }

}

export default greenSpaces