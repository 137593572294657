import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { ThemeProvider } from './context/ThemeContext'; // Import your custom ThemeProvider
import './chartConfig'
import './index.css'; // Adjust the path if necessary
import { DateFilterProvider } from './context/DateFilterContext';



ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
    <DateFilterProvider>
      <App />
      </DateFilterProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
