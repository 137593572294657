const waterUasge = {
  2024:{
  Jan: {
    3: {
      totalWaterConsumption: { value: 4050, unit: 'm³' },
      'Water Recycling Rate': { value: 11, unit: '%' },
      'Recycled Water Volume': { value: 1250, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 85, unit: 'm³' },
      'Water Efficiency': { value: 0.42, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 320, unit: 'm³' },
    },
    20: {
      totalWaterConsumption: { value: 3900, unit: 'm³' },
      'Water Recycling Rate': { value: 9, unit: '%' },
      'Recycled Water Volume': { value: 1100, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 75, unit: 'm³' },
      'Water Efficiency': { value: 0.39, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 280, unit: 'm³' },
    },
  },
  Feb: {
    10: {
      totalWaterConsumption: { value: 4100, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1220, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 310, unit: 'm³' },
    },
    25: {
      totalWaterConsumption: { value: 4000, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
  },
  Mar: {
    5: {
      totalWaterConsumption: { value: 4150, unit: 'm³' },
      'Water Recycling Rate': { value: 11, unit: '%' },
      'Recycled Water Volume': { value: 1270, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 85, unit: 'm³' },
      'Water Efficiency': { value: 0.43, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 320, unit: 'm³' },
    },
    18: {
      totalWaterConsumption: { value: 3950, unit: 'm³' },
      'Water Recycling Rate': { value: 9.5, unit: '%' },
      'Recycled Water Volume': { value: 1180, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 78, unit: 'm³' },
      'Water Efficiency': { value: 0.39, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 290, unit: 'm³' },
    },
    30: {
      totalWaterConsumption: { value: 4200, unit: 'm³' },
      'Water Recycling Rate': { value: 10.8, unit: '%' },
      'Recycled Water Volume': { value: 1260, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 84, unit: 'm³' },
      'Water Efficiency': { value: 0.42, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 315, unit: 'm³' },
    },
  },
  Apr: {
    7: {
      totalWaterConsumption: { value: 3900, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
    22: {
      totalWaterConsumption: { value: 4300, unit: 'm³' },
      'Water Recycling Rate': { value: 12, unit: '%' },
      'Recycled Water Volume': { value: 1300, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 90, unit: 'm³' },
      'Water Efficiency': { value: 0.44, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 340, unit: 'm³' },
    },
  },
  May: {
    12: {
      totalWaterConsumption: { value: 4200, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1250, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 315, unit: 'm³' },
    },
    28: {
      totalWaterConsumption: { value: 3950, unit: 'm³' },
      'Water Recycling Rate': { value: 9.8, unit: '%' },
      'Recycled Water Volume': { value: 1190, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 78, unit: 'm³' },
      'Water Efficiency': { value: 0.39, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 290, unit: 'm³' },
    },
  },
  Jun: {
    6: {
      totalWaterConsumption: { value: 4000, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
    19: {
      totalWaterConsumption: { value: 4100, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1220, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 310, unit: 'm³' },
    },
  },
  Jul: {
    4: {
      totalWaterConsumption: { value: 4300, unit: 'm³' },
      'Water Recycling Rate': { value: 12, unit: '%' },
      'Recycled Water Volume': { value: 1300, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 90, unit: 'm³' },
      'Water Efficiency': { value: 0.43, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 340, unit: 'm³' },
    },
    21: {
      totalWaterConsumption: { value: 3950, unit: 'm³' },
      'Water Recycling Rate': { value: 9.8, unit: '%' },
      'Recycled Water Volume': { value: 1190, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 78, unit: 'm³' },
      'Water Efficiency': { value: 0.39, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 290, unit: 'm³' },
    },
  },
  Aug: {
    5: {
      totalWaterConsumption: { value: 4200, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1250, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 315, unit: 'm³' },
    },
    23: {
      totalWaterConsumption: { value: 4000, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
  },
  Sep: {
    7: {
      totalWaterConsumption: { value: 4100, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1220, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 310, unit: 'm³' },
    },
    18: {
      totalWaterConsumption: { value: 4050, unit: 'm³' },
      'Water Recycling Rate': { value: 10.2, unit: '%' },
      'Recycled Water Volume': { value: 1210, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 81, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 305, unit: 'm³' },
    },
  },
  Oct: {
    9: {
      totalWaterConsumption: { value: 4200, unit: 'm³' },
      'Water Recycling Rate': { value: 11, unit: '%' },
      'Recycled Water Volume': { value: 1240, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.42, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 320, unit: 'm³' },
    },
    29: {
      totalWaterConsumption: { value: 4000, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
  },
  Nov: {
    11: {
      totalWaterConsumption: { value: 4100, unit: 'm³' },
      'Water Recycling Rate': { value: 10.5, unit: '%' },
      'Recycled Water Volume': { value: 1220, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 82, unit: 'm³' },
      'Water Efficiency': { value: 0.41, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 310, unit: 'm³' },
    },
    20: {
      totalWaterConsumption: { value: 4050, unit: 'm³' },
      'Water Recycling Rate': { value: 10.2, unit: '%' },
      'Recycled Water Volume': { value: 1210, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 81, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 305, unit: 'm³' },
    },
  },
  Dec: {
    8: {
      totalWaterConsumption: { value: 4300, unit: 'm³' },
      'Water Recycling Rate': { value: 12, unit: '%' },
      'Recycled Water Volume': { value: 1300, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 90, unit: 'm³' },
      'Water Efficiency': { value: 0.44, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 340, unit: 'm³' },
    },
    19: {
      totalWaterConsumption: { value: 4000, unit: 'm³' },
      'Water Recycling Rate': { value: 10, unit: '%' },
      'Recycled Water Volume': { value: 1200, unit: 'm³' },
      'Water Saved by Low-Flow Fixture': { value: 80, unit: 'm³' },
      'Water Efficiency': { value: 0.4, unit: 'm³/m²/year' },
      'Rainwater Harvested': { value: 300, unit: 'm³' },
    },
  },
},
2023:{
    Jan: {
      5: {
        "totalWaterConsumption": { "value": 3950, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1150, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 85, "unit": "m³" },
        "Water Efficiency": { "value": 0.38, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 290, "unit": "m³" }
      },
      17: {
        "totalWaterConsumption": { "value": 4020, "unit": "m³" },
        "Water Recycling Rate": { "value": 9, "unit": "%" },
        "Recycled Water Volume": { "value": 1180, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 90, "unit": "m³" },
        "Water Efficiency": { "value": 0.42, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 310, "unit": "m³" }
      }
    },
    February: {
      "10": {
        "totalWaterConsumption": { "value": 4080, "unit": "m³" },
        "Water Recycling Rate": { "value": 11, "unit": "%" },
        "Recycled Water Volume": { "value": 1190, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 95, "unit": "m³" },
        "Water Efficiency": { "value": 0.41, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 315, "unit": "m³" }
      },
      "21": {
        "totalWaterConsumption": { "value": 3960, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1175, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 82, "unit": "m³" },
        "Water Efficiency": { "value": 0.39, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 300, "unit": "m³" }
      }
    },
    "March": {
      "3": {
        "totalWaterConsumption": { "value": 4100, "unit": "m³" },
        "Water Recycling Rate": { "value": 13, "unit": "%" },
        "Recycled Water Volume": { "value": 1210, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 100, "unit": "m³" },
        "Water Efficiency": { "value": 0.43, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 320, "unit": "m³" }
      },
      "14": {
        "totalWaterConsumption": { "value": 3990, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1195, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 88, "unit": "m³" },
        "Water Efficiency": { "value": 0.4, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 295, "unit": "m³" }
      }
    },
    "April": {
      "7": {
        "totalWaterConsumption": { "value": 4120, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1160, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 95, "unit": "m³" },
        "Water Efficiency": { "value": 0.39, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 325, "unit": "m³" }
      },
      "25": {
        "totalWaterConsumption": { "value": 4035, "unit": "m³" },
        "Water Recycling Rate": { "value": 11, "unit": "%" },
        "Recycled Water Volume": { "value": 1200, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 85, "unit": "m³" },
        "Water Efficiency": { "value": 0.41, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 330, "unit": "m³" }
      }
    },
    "May": {
      "10": {
        "totalWaterConsumption": { "value": 3900, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1220, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 93, "unit": "m³" },
        "Water Efficiency": { "value": 0.4, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 285, "unit": "m³" }
      },
      "24": {
        "totalWaterConsumption": { "value": 4085, "unit": "m³" },
        "Water Recycling Rate": { "value": 11, "unit": "%" },
        "Recycled Water Volume": { "value": 1140, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 90, "unit": "m³" },
        "Water Efficiency": { "value": 0.39, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 305, "unit": "m³" }
      }
    },
    "June": {
      "5": {
        "totalWaterConsumption": { "value": 4200, "unit": "m³" },
        "Water Recycling Rate": { "value": 13, "unit": "%" },
        "Recycled Water Volume": { "value": 1180, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 102, "unit": "m³" },
        "Water Efficiency": { "value": 0.43, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 350, "unit": "m³" }
      },
      "18": {
        "totalWaterConsumption": { "value": 4050, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1170, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 95, "unit": "m³" },
        "Water Efficiency": { "value": 0.4, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 310, "unit": "m³" }
      }
    },
    "July": {
      "8": {
        "totalWaterConsumption": { "value": 3970, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1120, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 88, "unit": "m³" },
        "Water Efficiency": { "value": 0.39, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 275, "unit": "m³" }
      },
      "22": {
        "totalWaterConsumption": { "value": 4025, "unit": "m³" },
        "Water Recycling Rate": { "value": 9, "unit": "%" },
        "Recycled Water Volume": { "value": 1130, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 91, "unit": "m³" },
        "Water Efficiency": { "value": 0.41, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 290, "unit": "m³" }
      }
    },
    "August": {
      "5": {
        "totalWaterConsumption": { "value": 4150, "unit": "m³" },
        "Water Recycling Rate": { "value": 13, "unit": "%" },
        "Recycled Water Volume": { "value": 1220, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 105, "unit": "m³" },
        "Water Efficiency": { "value": 0.44, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 350, "unit": "m³" }
      },
      "20": {
        "totalWaterConsumption": { "value": 4080, "unit": "m³" },
        "Water Recycling Rate": { "value": 11, "unit": "%" },
        "Recycled Water Volume": { "value": 1180, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 90, "unit": "m³" },
        "Water Efficiency": { "value": 0.42, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 310, "unit": "m³" }
      }
    },
    "September": {
      "4": {
        "totalWaterConsumption": { "value": 4010, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1160, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 92, "unit": "m³" },
        "Water Efficiency": { "value": 0.39, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 300, "unit": "m³" }
      },
      "15": {
        "totalWaterConsumption": { "value": 4125, "unit": "m³" },
        "Water Recycling Rate": { "value": 9, "unit": "%" },
        "Recycled Water Volume": { "value": 1130, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 89, "unit": "m³" },
        "Water Efficiency": { "value": 0.4, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 310, "unit": "m³" }
      }
    },
    "October": {
      "3": {
        "totalWaterConsumption": { "value": 3995, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1175, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 88, "unit": "m³" },
        "Water Efficiency": { "value": 0.41, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 315, "unit": "m³" }
      },
      "21": {
        "totalWaterConsumption": { "value": 4100, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1160, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 92, "unit": "m³" },
        "Water Efficiency": { "value": 0.42, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 340, "unit": "m³" }
      }
    },
    "November": {
      "7": {
        "totalWaterConsumption": { "value": 4175, "unit": "m³" },
        "Water Recycling Rate": { "value": 13, "unit": "%" },
        "Recycled Water Volume": { "value": 1200, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 103, "unit": "m³" },
        "Water Efficiency": { "value": 0.44, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 355, "unit": "m³" }
      },
      "27": {
        "totalWaterConsumption": { "value": 4020, "unit": "m³" },
        "Water Recycling Rate": { "value": 10, "unit": "%" },
        "Recycled Water Volume": { "value": 1185, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 85, "unit": "m³" },
        "Water Efficiency": { "value": 0.42, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 330, "unit": "m³" }
      }
    },
    "December": {
      "9": {
        "totalWaterConsumption": { "value": 4000, "unit": "m³" },
        "Water Recycling Rate": { "value": 12, "unit": "%" },
        "Recycled Water Volume": { "value": 1150, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 92, "unit": "m³" },
        "Water Efficiency": { "value": 0.41, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 320, "unit": "m³" }
      },
      "23": {
        "totalWaterConsumption": { "value": 4190, "unit": "m³" },
        "Water Recycling Rate": { "value": 11, "unit": "%" },
        "Recycled Water Volume": { "value": 1190, "unit": "m³" },
        "Water Saved by Low-Flow Fixture": { "value": 95, "unit": "m³" },
        "Water Efficiency": { "value": 0.43, "unit": "m³/m²/year" },
        "Rainwater Harvested": { "value": 340, "unit": "m³" }
      },
    },
  },
};

export default waterUasge