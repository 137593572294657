import React, { useState, useEffect } from 'react';
import { Line, Bar, Pie, Doughnut,Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement, ArcElement,Filler } from 'chart.js';
import { IconButton, Menu, MenuItem, Box, Typography } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';


ChartJS.register(
  LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement, 
  ArcElement, Filler
);

const ChartCard = ({ metricName, rawData, year, lineColor, defaultChart }) => {
  const [chartType, setChartType] = useState(defaultChart); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [colors, setColors] = useState([]); 

  useEffect(() => {

    const colorSet = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', 
      '#FF9F40', '#E7E9ED', '#C9CBCF'
    ];
    setColors(colorSet);
  }, []);

  useEffect(() => {
    const savedChartType = localStorage.getItem(`${metricName}-chartType`);
    if (savedChartType) {
      setChartType(savedChartType); 
    }
  }, [metricName]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChartTypeChange = (type) => {
    setChartType(type); 
    localStorage.setItem(`${metricName}-chartType`, type); 
    handleClose();
  };

  const transformData = () => {
    if (!rawData || !rawData[year]) return { labels: [], datasets: [] };

    const dataPoints = Object.keys(rawData[year]).map((month) => ({
      month,
      value: rawData[year][month][metricName],
    }));

    const colorSet = colors.length > 0 ? colors : ['#FF6384']; 

    return {
      labels: dataPoints?.map((dp) => dp.month),
      datasets: [
        {
          label: metricName,
          data: dataPoints?.map((dp) => dp.value),
          backgroundColor: chartType === 'bar' || chartType === 'doughnut' || chartType === 'pie' ? colorSet.slice(0, dataPoints.length) : 'rgba(75, 192, 192, 0.2)', 
          borderColor: chartType === 'line' ? lineColor : '#E7E9ED',
          borderWidth: chartType === 'line' ? 1 : 0, 
          fill: chartType === 'bar' || chartType === 'area' ? true : false,
        },
      ],
    };
  };

  const chartData = transformData();

  const defaultOptions = {
    plugins: {
      legend: chartType === 'pie' || chartType === 'doughnut' ? {
        display: true,
        position: 'right', 
        labels: {
          color: 'rgba(0, 0, 0, 0.7)',
          font: {
            size: 14,
          },
          padding: 20, 
        },
      } : false,
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Value: ${tooltipItem.raw}`,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        right: 150, 
        bottom: 20,
        left: 20,
      },
    },
    scales: chartType === 'bar' ? {
      x: {
        grid: {
          display: false, 
        },
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values',
        },
      },
    } : false,
  };

  const renderChart = () => {
    switch (chartType) {
      case 'line':
        return <Line data={chartData} options={defaultOptions} />;
      case 'bar':
        return <Bar data={chartData} options={defaultOptions} />;
      case 'pie':
        return <Pie data={chartData} options={defaultOptions} />;
      case 'doughnut':
        return <Doughnut data={chartData} options={defaultOptions} />;
      case 'scatter':
        return <Scatter data={chartData} options={defaultOptions} />;
      default:
        return <Line data={chartData} options={defaultOptions} />;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '1rem',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '550px',
        position: 'relative',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {metricName}
      </Typography>

      <Box
        sx={{
          position: 'relative',
          height: '300px', 
          width: '120%',
        }}
      >
        {renderChart()}
      </Box>

      <IconButton
        aria-controls="chart-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'inherit',
        }}
      >
        <EqualizerIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChartTypeChange('line')}>Line Chart</MenuItem>
        <MenuItem onClick={() => handleChartTypeChange('bar')}>Bar Chart</MenuItem>
        <MenuItem onClick={() => handleChartTypeChange('doughnut')}>Doughnut Chart</MenuItem>
        <MenuItem onClick={() => handleChartTypeChange('pie')}>Pie Chart</MenuItem>
        <MenuItem onClick={() => handleChartTypeChange('area')}>Area Chart</MenuItem>
      </Menu>
    </Box>
  );
};

export default ChartCard;
