// src/context/ThemeContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

// Create the ThemeContext
const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useThemeUpdate = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeUpdate must be used within a ThemeProvider');
  }
  return context;
};

// Create the ThemeProvider
export const ThemeProvider = ({ children }) => {
  // Get the initial theme color from localStorage or set default
  const initialThemeColor = localStorage.getItem('themeColor') || '#1976d2';
  
  const [themeColor, setThemeColor] = useState(initialThemeColor);

  // Update localStorage whenever themeColor changes
  useEffect(() => {
    localStorage.setItem('themeColor', themeColor);
  }, [themeColor]);

  // Create a theme with the current theme color
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
      },
      secondary: {
        main: '#dc004e', // Default secondary color
      },
    },
  });

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
