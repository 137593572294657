import React, { useState } from 'react';
import LineChart from './charts/LineChart';
import ChartCard from './charts/ChartCard';
import LineGraphForOverview from './charts/LineGraphForOverview';

const Card = ({ name, img, color1, color2, content, totalKey, textColor, graphBackground, lineColor, graphData, graphLabels  }) => {

  // console.log('valuesss---', graphData)

  // const cardsData = [
  //   {
  //     name: 'Energy',
  //     img: './energy.svg',
  //     color1: 'bg-[#7A40F2]',
  //     color2: 'bg-[#DECEFF]',
  //     textColor: 'text-[#7A40F2]',
  //     totalKey: 'Total Consumption',
  //     content: { 
  //       'January': 450, 
  //       'February': 480, 
  //       'March': 430, 
  //       'April': 500 
  //     }
  //   },
  //   // More cards...
  // ];

  // console.log('content----',content)


  const totalEntry = Object.entries(content).find(([key]) => key === totalKey);
  
  // Filter out the totalKey and limit to 3 remaining entries
  const remainingEntries = Object.entries(content).filter(([key]) => key !== totalKey).slice(0, 3);
  
  // Set a condition for moving the card up
  const shouldMoveUp = name === "Transportation" || name === "Building" || name === "Compliance";
  const shouldMoveDown = name === "Energy" || name === "Carbon Footprint" || name === "Water";
  const middleRow = name === "Waste" || name === "Air Quality" || name === "Green Spaces";
  const rightRow = name === "Water" || name === "Green Spaces" || name === "Compliance";
  const leftCol = name === "Energy" || name === "Waste" || name === "Transportation";
  
  // State to control graph visibility
  const [isHovered, setIsHovered] = useState(false);

  // console.log('++++----', `w-12 h-12 px-[13px] py-[11px] bg-${color1} rounded-tl-[20px] rounded-br-[20px] justify-center items-center gap-1.5 flex`)

  return (
    <div 
  className={`relative w-full h-full shadow flex-col justify-start items-start inline-flex mr-4 rounded-tl-[20px] rounded-tr-[20px] rounded-br-[20px] rounded-bl-[20px] hover:w-[450px] xl:hover:w-[450px] 2xl:hover:w-[1000px]
  hover:z-50 hover:scale-110 lg:hover:h-auto md:hover:h-auto transition-all duration-500 ease-in-out 
  ${shouldMoveUp ? 'hover:-translate-y-72 2xl:hover:-translate-y-96' : ''} ${shouldMoveDown ? 'hover:translate-y-20' : ''} ${middleRow ? 'hover:-translate-y-32' : ''} ${rightRow ? 'hover:-translate-x-32' : ''} ${leftCol ? 'hover:translate-x-10' : ''} `} // Default z-index for non-hovered state
  onMouseEnter={() => setIsHovered(true)}  // Set hover state to true on mouse enter
  onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
>

      {/* Header section with image and title */}
      <div className={`self-stretch ${color2} rounded-tl-[20px] rounded-tr-[20px] justify-start items-center inline-flex`}>
  <div className={`w-14 2xl:w-[80px] h-14 px-[13px] py-[11px] ${color1} rounded-tl-[20px] rounded-br-[20px] justify-center items-center gap-1.5 flex `}>
    <img src={img} alt={name} className="w-12 h-8 rounded-full" />
  </div>
  <div className={`w-[full] h-12 px-3 py-2.5 justify-start items-center gap-2.5 flex`}>
    <div className={`${textColor}  font-semibold xl:text-[16px] 2xl:text-[30px]`}>
      {name}
    </div>
  </div>

  <div className="pr-5 justify-end items-center gap-2.5 flex">
    <div className="w-[65px] h-12 relative" />
  </div>
</div>

      {/* Content area for displaying other details */}
      <div className="self-stretch bg-white rounded-bl-[20px] rounded-br-[20px] flex-col justify-start items-start gap-0.25 flex h-full truncate">
        <div className="self-stretch bg-white flex-col justify-start items-start flex p-4 rounded-bl-[20px] rounded-br-[20px] h-full ">
          {/* Total entry */}
          {totalEntry && (
            <div className="flex justify-between items-center w-full p-[0.5px]">
              <div 
                className="text-[#040219] font-bold ml-2 mt-1 capitalize sm:w-[60%] w-full truncate 2xl:text-[32px] 2xl:py-6 2xl:pb-10"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={totalEntry[0]}   // Tooltip for full text on hover
              >
                {totalEntry[0]}
              </div>
              <div className="text-[#040219] mr-3 mt-1 sm:w-[40%] w-full truncate text-right 2xl:text-[38px] 2xl:pb-6 font-[900]">
                {totalEntry[1].value} {totalEntry[1].unit}
              </div>
            </div>
              )}

            {totalEntry && <hr className="w-full border-t-2 border-gray-300 my-2 " />}

            {remainingEntries.map(([key, value], index) => (
              <div key={index} className="flex justify-between items-center w-full p-1">
                <div 
                  className="text-[#747d92] text-[14px] 2xl:text-[30px] font-semibold 2xl:font-normal ml-2 mt-1 capitalize sm:w-[60%] w-full truncate 2xl:mt-6 "
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={key}   // Tooltip for full text on hover
                >
                  {key}
                </div>
                <div className="text-[#040219] text-[14px] 2xl:text-[30px] font-semibold mr-3 mt-1 sm:w-[40%] w-full text-right truncate">
                  {value.value} {value.unit}
                </div>
              </div>
            ))}


        </div>

        {/* Graph Display */}
        {isHovered && (
  <div className="p-4 w-full h-auto mb-0">
    <div className={`${graphBackground} p-4 h-auto w-full`} style={{ borderRadius: '20px' }}>
      <h3 className={`${textColor} text-[14px] 2xl:text-[28px] font-semibold`}>{totalEntry[0]}</h3>
      <div className="h-[200px] 2xl:h-[400px] w-full"> {/* Set a fixed height for the graph container */}
        <LineGraphForOverview 
        graphData={graphData}
        graphLabels={graphLabels}
          graphBackground={graphBackground} 
          lineColor={lineColor} 
        />
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default Card;
